import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BannerService } from './banner.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { BACKEND_FILE, crypt } from '@app/app.api';
import { SimpleCrypt } from 'ngx-simple-crypt';
import swal from 'sweetalert2';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent implements OnInit {

  id: number

  idBanner:number

  foto: any[]
  ordem: any[] = []
  imagens: any[] = []

  follow = true;
  enablePan = true;

  idEscola: number

  index = 0;
  speed = 3000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = false;
  avatars = []
  simpleCrypt = new SimpleCrypt();
  
  constructor(private bannerService: BannerService,
              public dialog: MatDialog) { 
                
                let storage = localStorage.getItem("sapiens")?JSON.parse(localStorage.getItem("sapiens")):''
                this.idEscola = Number(this.simpleCrypt.decode(crypt,storage._idE))
                
              }

  ngOnInit() {    
    this.load()
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.imagens, event.previousIndex, event.currentIndex);
    this.reordenar()

    this.avatars = []

    this.imagens.forEach(element => {
      this.avatars.push(
        {url: element, title: element}
      );
    })
  }

  excluir(index:number){

    swal.fire({
      title: 'Progressão',
      text: 'Deseja realmente excluir esse banner?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {
           
        this.bannerService.deletarBanner(this.imagens[index].ordem).subscribe(result=>{
          this.id = this.id -1
          this.imagens.splice(index,1)
          this.avatars.splice(index,1)
        })

      }
    })

  }

  load(){

    this.bannerService.obterBannerFoto().subscribe(result=>{
     
      let x=1
      this.id = result==null? 1 : result.length +1 

      this.avatars = []

      this.imagens = []

      result.forEach(element => {
        this.imagens.push({"arquivo":BACKEND_FILE + "/" + this.idEscola + "/banner/"+ element, "ordem": element})
        this.avatars.push(
          {url: BACKEND_FILE + "/" + this.idEscola + "/banner/"+ element, title: element}
        );
        x++
      });
      this.autoplay = true;
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadBannerDialog, {
      width: '800px',
      height: '595px',
      disableClose: true,
      data:{ id : this.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.load()
      }, 3000 );
    });
  }

  reordenar(){
    let ordenacao = []
    let x = 1

    this.imagens.forEach(element => {
      ordenacao.push(element.ordem)
      element.ordem = x
      x++
    });
    
    this.bannerService.reordenarBanner(ordenacao).subscribe(result=>{
      setTimeout( () => {
        this.load()
      }, 500 );
    })
  }
}

export interface DialogData {
  id: number;
}

@Component({
  selector: 'uploadbanner',
  templateUrl: './uploadbanner.html',
})

export class UploadBannerDialog {
  imageChangedEvent: any = undefined;
  croppedImage: any = ''

  constructor(private bannerService: BannerService,
              public dialogRef: MatDialogRef<UploadBannerDialog>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.bannerService.uploadBanner(this.croppedImage, this.data.id).subscribe(result=>{

    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }
}