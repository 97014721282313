import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { HomeComponent } from '@pages/home/home.component';
import { LoggedInGuard } from '@pages/security/loggedin.guard';
import { LoginComponent } from '@pages/security/login/login.component';
import { NotFoundComponent } from '@pages/not-found/not-found.component';

import { RouterModule, Routes } from '@angular/router';
import { EsqueciSenhaComponent } from '@pages/security/esqueci-senha/esqueci-senha.component';
import { RedefinirsenhaComponent } from '@pages/security/redefinirsenha/redefinirsenha.component';
import { AlterarsenhaComponent } from '@pages/security/alterarsenha/alterarsenha.component';
import { AlunoComponent } from '@pages/aluno/aluno.component';
import { FormalunoComponent } from '@pages/aluno/formaluno/formaluno.component';
import { FuncionarioComponent } from '@pages/funcionario/funcionario.component';
import { FormfuncionarioComponent } from '@pages/funcionario/formfuncionario/formfuncionario.component';
import { FormturmaComponent } from '@pages/turma/formturma/formturma.component';
import { TurmaComponent } from '@pages/turma/turma.component';
import { ExtracurricularComponent } from '@pages/extracurricular/extracurricular.component';
import { FormextracurricularComponent } from '@pages/extracurricular/formextracurricular/formextracurricular.component';
import { CropimageComponent } from '@pages/cropimage/cropimage.component';
import { MensalidadeComponent } from '@pages/financeiro/mensalidade/mensalidade.component';
import { AboutComponent } from '@pages/about/about.component';
import { RecebimentomensalidadeComponent } from '@pages/financeiro/recebimentomensalidade/recebimentomensalidade.component';
import { EmailmensalidadeComponent } from '@pages/email/emailmensalidade/emailmensalidade.component';
import { DisciplinaComponent } from '@pages/turma/disciplina/disciplina.component';
import { FormdisciplinaComponent } from '@pages/turma/disciplina/formdisciplina/formdisciplina.component';
import { EmailmensagemComponent } from '@pages/email/emailmensagem/emailmensagem.component';
import { AvaliacaoComponent } from '@pages/notas/avaliacao/avaliacao.component';
import { NotaComponent } from '@pages/notas/nota/nota.component';
import { BoletimComponent } from '@pages/notas/boletim/boletim.component';
import { BoletimimpressaoComponent } from '@pages/impressao/boletimimpressao/boletimimpressao.component';
import { FormavaliacaoComponent } from '@pages/notas/avaliacao/formavaliacao/formavaliacao.component';
import { ConselhoclasseComponent } from '@pages/documento/conselhoclasse/conselhoclasse.component';
import { AcessoComponent } from '@pages/administracao/acesso/acesso.component';
import { FaturamentomensalComponent } from '@pages/financeiro/faturamentomensal/faturamentomensal.component';
import { BannerComponent } from '@pages/banner/banner.component';
import { GeracaoboletoComponent } from '@pages/financeiro/geracaoboleto/geracaoboleto.component';
import { BoletocartaoComponent } from '@pages/financeiro/boletocartao/boletocartao.component';
import { ExtratobancarioComponent } from '@pages/financeiro/extratobancario/extratobancario.component';
import { TransacoesComponent } from '@pages/financeiro/transacoes/transacoes.component';
import { EditarboletoComponent } from '@pages/financeiro/editarboleto/editarboleto.component';
import { EmailinternoComponent } from '@pages/emailinterno/emailinterno.component';
import { AbriranoComponent } from '@pages/administracao/abrirano/abrirano.component';
import { FichaindividualComponent } from '@pages/impressao/fichaindividual/fichaindividual.component';
import { AlteraranoComponent } from '@pages/alterarano/alterarano.component';
import { AtaconselhoclasseComponent } from '@pages/impressao/ataconselhodeclasse/ataconselhodeclasse.component';
import { DiarioclasseComponent } from '@pages/impressao/diarioclasse/diarioclasse.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'esquecisenha', component: EsqueciSenhaComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:to', component: LoginComponent },
  { path: 'redefinirsenha/:link', component: RedefinirsenhaComponent },
  { path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [LoggedInGuard] },
      { path: 'alterarsenha', component: AlterarsenhaComponent, canActivate: [LoggedInGuard] },

      { path: 'about', component: AboutComponent, canActivate: [LoggedInGuard] },
      { path: 'abrirano', component: AbriranoComponent, canActivate: [LoggedInGuard] },
      { path: 'acessos', component: AcessoComponent, canActivate: [LoggedInGuard] },

      { path: 'alteraranoletivo', component: AlteraranoComponent, canActivate: [LoggedInGuard] },

      { path: 'alunos', component: AlunoComponent, canActivate: [LoggedInGuard] },
      { path: 'alunos/form', component: FormalunoComponent, canActivate: [LoggedInGuard] },
      { path: 'alunos/form/:id', component: FormalunoComponent, canActivate: [LoggedInGuard] },

      { path: 'banner', component: BannerComponent, canActivate: [LoggedInGuard] },

      { path: 'diario', loadChildren: () => import('@pages/diario/diario.module').then(m => m.DiarioModule) },
      
      { path: 'documentos/conselhoclasse', component: ConselhoclasseComponent, canActivate: [LoggedInGuard] },

      { path: 'emailinterno', component: EmailinternoComponent, canActivate: [LoggedInGuard] },

      { path: 'email/generico', component: EmailmensagemComponent, canActivate: [LoggedInGuard] },

      { path: 'extracurriculares', component: ExtracurricularComponent, canActivate: [LoggedInGuard] },
      { path: 'extracurriculares/form', component: FormextracurricularComponent, canActivate: [LoggedInGuard] },
      { path: 'extracurriculares/form/:id', component: FormextracurricularComponent, canActivate: [LoggedInGuard] },   
      
      { path: 'financeiro/boletocartao', component: BoletocartaoComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/emailmensalidade', component: EmailmensalidadeComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/editarboleto', component: EditarboletoComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/extrato', component: ExtratobancarioComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/faturamentodia', component: MensalidadeComponent, canActivate: [LoggedInGuard] }, 
      { path: 'financeiro/faturamentomensal', component: MensalidadeComponent, canActivate: [LoggedInGuard] },         
      { path: 'financeiro/faturamentomensalgrafico', component: FaturamentomensalComponent, canActivate: [LoggedInGuard] }, 
      { path: 'financeiro/geracaoboleto', component: GeracaoboletoComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/inadimplentes', component: MensalidadeComponent, canActivate: [LoggedInGuard] }, 
      { path: 'financeiro/mensalidade', component: MensalidadeComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/recebimentomensalidade', component: RecebimentomensalidadeComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/recebimentomensalidade/:matricula', component: RecebimentomensalidadeComponent, canActivate: [LoggedInGuard] },
      { path: 'financeiro/transferenciasbancarias', component: TransacoesComponent, canActivate: [LoggedInGuard] },
            
      { path: 'funcionarios', component: FuncionarioComponent, canActivate: [LoggedInGuard] },
      { path: 'funcionarios/form', component: FormfuncionarioComponent, canActivate: [LoggedInGuard] },
      { path: 'funcionarios/form/:id', component: FormfuncionarioComponent, canActivate: [LoggedInGuard] },

      { path: 'impressao/boletim', component: BoletimimpressaoComponent, canActivate: [LoggedInGuard] },
      { path: 'impressao/ataconselhodeclasse', component: AtaconselhoclasseComponent, canActivate: [LoggedInGuard] },
      { path: 'impressao/diario', component: DiarioclasseComponent, canActivate: [LoggedInGuard] },
      { path: 'impressao/fichaindividual', component: FichaindividualComponent, canActivate: [LoggedInGuard] },
      
      { path: 'notas', component: NotaComponent, canActivate: [LoggedInGuard] },
      { path: 'notas/avaliacoes', component: AvaliacaoComponent, canActivate: [LoggedInGuard] },
      { path: 'notas/avaliacoes/form', component: FormavaliacaoComponent, canActivate: [LoggedInGuard] },
      { path: 'notas/avaliacoes/form/:id', component: FormavaliacaoComponent, canActivate: [LoggedInGuard] },
      { path: 'notas/boletins', component: BoletimComponent, canActivate: [LoggedInGuard] },
      
      { path: 'turmas', component: TurmaComponent, canActivate: [LoggedInGuard] },
      { path: 'turmas/disciplina', component: DisciplinaComponent, canActivate: [LoggedInGuard] },
      { path: 'turmas/form', component: FormturmaComponent, canActivate: [LoggedInGuard] },
      { path: 'turmas/form/:id', component: FormturmaComponent, canActivate: [LoggedInGuard] },
      { path: 'turmas/formdisciplina', component: FormdisciplinaComponent, canActivate: [LoggedInGuard] },
      { path: 'turmas/formdisciplina/:idTurma', component: FormdisciplinaComponent, canActivate: [LoggedInGuard] },
      
      { path: 'uploadimage', component: CropimageComponent, canActivate: [LoggedInGuard] },             
      { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard] },
      { path: '**', component: NotFoundComponent }
    ]
  },

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }