import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { Turma, Disciplina } from 'src/app/shared/model/turma.model';
import { NotaService } from '@shared/service/nota.service';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { LoginService } from 'src/app/shared/service/login.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FuncionarioService } from '@app/shared/service/funcionario.service';

@Component({
  selector: 'app-nota',
  templateUrl: './nota.component.html',
  styleUrls: ['./nota.component.scss']
})

export class NotaComponent implements OnInit, OnDestroy {
  
  avaliacao:string[]= ['nome','id']

  columns: any[] = [{ columnDef: 'nome', header: 'Nome', cell: 'nome', size: '30', edicao: '0', local: '0'}]
  displayedColumns: any
  dataSource: any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ano: number
  tipoFuncionario: string
  professor: string
  idTurma: number
  idDisciplina: number

  turmas: Turma[]
  disciplinas: Disciplina[]

  notas: any[] = ['','0.0','0.5','1.0','1.5','2.0','2.5','3.0','3.5','4.0','4.5',
                  '5.0','5.5','6.0','6.5','7.0','7.5','8.0','8.5','9.0','9.5', '10.0']
  avaliacaoGrid: string[] = [] 
  
  subscription: Subscription = new Subscription();
	onDestroyer$ = new Subject()
  
  constructor(private turmaService: TurmaService,
              private loginService: LoginService,
              private notaService: NotaService,
              private funcionarioService: FuncionarioService) { 
                this.notaService.obterAvaliacoes(this.loginService.retornarAno())
                .pipe(takeUntil(this.onDestroyer$))
                .subscribe(result=>{
                  let tamanho = (70/ result.length)
                  let x : number = 0
                  result.forEach(element => {
                    let liberacao = 'N'
                    if(element.dataLiberacao!=null){
                      if(moment().format()>= moment(element.dataLiberacao).format()){
                        liberacao = 'S'
                      }
                    }
                    this.columns.push({ columnDef: element.id.toString(), header: element.nomeReduzido== undefined? element.nome : element.nomeReduzido, cell:element.id.toString(), size: tamanho, edicao: liberacao=='N' ?'0':'1', local:x })
                    x++
                  });
                  this.displayedColumns = this.columns.map(c => c.columnDef);
                })
              }

  ngOnInit() {
    this.ano = this.loginService.retornarAno()
    this.tipoFuncionario = this.loginService.usuario().tipo  

    if(this.tipoFuncionario == '3'){
      this.professor = this.loginService.usuario().id
      this.funcionarioService.obterTurmaPorProfessor(this.professor , this.loginService.retornarAno())
        .pipe(takeUntil(this.onDestroyer$))
        .subscribe(result => {
          this.turmas = result  
      })
    } else {
      this.turmaService.obterTurmaComboMensalidade(this.loginService.retornarAno())
        .pipe(takeUntil(this.onDestroyer$))
        .subscribe(result => {
          this.turmas = result  
      })
    }
  }

  ngOnDestroy(): void {
    this.onDestroyer$.unsubscribe()
  }

  retornaAlunos(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.notaService.obterNotasTurma(this.idTurma, this.idDisciplina, this.loginService.retornarAno())
      .pipe(takeUntil(this.onDestroyer$))  
      .subscribe(retorno => {
        this.dataSource = new MatTableDataSource(retorno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        swal.close()
      })
  }

  retornaDisciplinas(){
    if(this.tipoFuncionario == '3'){
      this.funcionarioService.obterDisciplinaPorProfessor(this.professor , this.loginService.retornarAno(), this.idTurma)
        .pipe(takeUntil(this.onDestroyer$))
        .subscribe(result => {
          this.disciplinas = result  
      })
    } else {
      this.turmaService.obterDisciplinaTurma(this.idTurma, this.loginService.retornarAno())
        .pipe(takeUntil(this.onDestroyer$))
        .subscribe(result => {      
          this.disciplinas = result
      })
    }
  }

  salvarNota(idAluno: number, idAvaliacao: number, nota:string){
    let arrayNota: any[] =[]
    arrayNota.push({idAlunoTurma: idAluno, idDisciplina: this.idDisciplina, idAvaliacao: idAvaliacao, nota: nota, dataCadastro : moment().format()})
    this.notaService.createNota(arrayNota).pipe(takeUntil(this.onDestroyer$)).subscribe(result=>{

    })
  }
}
