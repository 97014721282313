import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { AlunoAutoComplete, Aluno, AlunoGrid } from "../model/aluno.model";


@Injectable()

export class AlunoService{

    constructor(private http: HttpClient){}

    create(aluno: Aluno): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/alunos`, aluno)
        .pipe(map((result: any) => result))
    }

    obterAlunoAutoComplete(ano:number): Observable<AlunoAutoComplete[]> {
        return this.http.get<AlunoAutoComplete[]>(`${BACKEND_API}/alunos/obteralunoautocomplete/${ano}`)
    }
   
    obterAlunosComboEmail(idTurma:number, ano:number): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/alunos/comboemail/${idTurma}/${ano}`)
    }

    obterAlunoComboTurma(idTurma:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/alunocomboturma/${idTurma}/${ano}`)
    }   

    obterAlunoListaPresencaDisciplina(dia:string, ano:number, disciplina:number, idTurma:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/listaboleto/${dia}/${ano}/${disciplina}/${idTurma}`)
    } 

    obterAlunoFoto(matricula:string, ano:number): Observable<any[]> {
        return this.http.post<any[]>(`${BACKEND_API}/files/alunofoto`,{"matricula":matricula,"ano": ano})
    }

    obterAlunoGridNota(idTurma:number, ano:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/alunos/gridnota/${idTurma}/${ano}`)
    }

    obterAlunoId(idAluno:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/id/${idAluno}`)
    }   

    obterAlunoIdAno(idAluno:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/idano/${idAluno}/${ano}`)
    }   

    obterAlunoListaBoleto(idTurma:number, ano:number): Observable<any> {
        return this.http.get<any[]>(`${BACKEND_API}/alunos/listaboleto/${idTurma}/${ano}`)
    } 

    obterAlunoMatricula(matriculaAluno:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/matricula/${matriculaAluno}`)
    }   

    obterAlunoMatriculaAno(matriculaAluno:string, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/matriculaano/${matriculaAluno}/${ano}`)
    }   

    obterAlunoLista(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/alunos/obterAlunoLista`)
    }    

    obterAlunoListaGrid(ano: number): Observable<AlunoGrid[]> {
        return this.http.get<AlunoGrid[]>(`${BACKEND_API}/alunos/grid/${ano}`)
    }  
    
    rematricula(aluno: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/alunos/rematricula`, aluno)
        .pipe(map((result: any) => result))
    }

    update(aluno: Aluno): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/alunos`, aluno)
        .pipe(map((result: any) => result))
    }

    uploadAlunoFoto(file: any, matricula:string, ano:string ): Observable<any>{

        let form = new FormData()

            form.append('file', file)
            form.append('matricula', matricula)
            form.append('ano', ano)

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }
        return this.http.post<any>(`${BACKEND_API}/files/uploadalunofoto`, form)
        .pipe(map((result: any) => result))
    }
}