import { ComponentsActions, ComponentsActionTypes } from './components.actions';

export interface ComponentsState {
  pageName?: string
  dataColumns?: any
  dataTable?: any
  action?: string
  filter?: string
}

export const initialComponentsState: ComponentsState = {
  pageName: undefined,
  dataColumns: undefined,
  dataTable: undefined,
  action: undefined,
  filter: undefined
};

export function componentsReducer(state = initialComponentsState,
                                  action: ComponentsActions): ComponentsState {
  switch (action.type) {
    
    case ComponentsActionTypes.CleanTable:
      return {
        pageName: undefined,
        dataColumns: undefined,
        dataTable: undefined,
        action: undefined,
        filter: undefined
      };
 
    case ComponentsActionTypes.EmitAction:
      return {
        pageName: state.pageName,
        dataColumns: state.dataColumns,
        dataTable: state.dataTable,
        action: action.payload.action,
        filter: state.filter
      };

    case ComponentsActionTypes.EmitFilter:
      return {
        pageName: state.pageName,
        dataColumns: state.dataColumns,
        dataTable: state.dataTable,
        action: state.action,
        filter: action.payload.filter
      };
      
    case ComponentsActionTypes.FillTable:
      return {
        pageName: action.payload.pageName,
        dataColumns: action.payload.dataColumns,
        dataTable: action.payload.dataTable,
        action: undefined,
        filter: undefined
      };

    default:
      return state;
  }
}
