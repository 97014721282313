import { Action } from '@ngrx/store';

export enum ComponentsActionTypes {
  CleanTable = '[Clean] Clean table',
  EmitAction = '[Action] Emit action', 
  EmitFilter = '[Filter] Emit filter', 
  FillTable = '[Fill] Fill table'  
}

export class clearTable implements Action {
  readonly type = ComponentsActionTypes.CleanTable;
}

export class emitAction implements Action {
  readonly type = ComponentsActionTypes.EmitAction;
  constructor(public payload: {action:any}) 
  { }
}

export class emitFilter implements Action {
  readonly type = ComponentsActionTypes.EmitFilter;
  constructor(public payload: {filter:any}) 
  { }
}

export class fillTable implements Action {
  readonly type = ComponentsActionTypes.FillTable;
  constructor(public payload: {pageName: any, dataColumns: any, dataTable: any}) 
  { }
}

export type ComponentsActions = 
clearTable | 
emitAction | 
emitFilter |
fillTable