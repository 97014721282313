import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotaService } from '@shared/service/nota.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { LoginService } from 'src/app/shared/service/login.service';
import { Avaliacao } from 'src/app/shared/model/nota.model';

@Component({
  selector: 'app-formavaliacao',
  templateUrl: './formavaliacao.component.html',
  styleUrls: ['./formavaliacao.component.scss']
})
export class FormavaliacaoComponent implements OnInit {

  cadastroForm: FormGroup
  avaliacao: Avaliacao[]

  id : number = undefined 

  minDateEnd: any
  podeExcluir: boolean = false

  constructor(private notaService: NotaService,
              private fb: FormBuilder,
              private loginService : LoginService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { 

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {
    this.cadastroForm = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      nomeReduzido: this.fb.control('', [Validators.required]),
      visivelBoletim: this.fb.control(''),
      dataLiberacao: this.fb.control(''),
      dataLiberacaoFim: this.fb.control(''),
      liberadoPai: this.fb.control('')
    })

    if(this.id!=undefined){
      this.load()
    }

  }
    
  load(){

    this.notaService.obterAvaliacaoId(this.id).subscribe(result => {
      this.cadastroForm.patchValue({
        nome: result.nome,
        nomeReduzido: result.nomeReduzido,
        visivelBoletim: result.visivelBoletim == 'S' ? true : false,          
        dataLiberacao: result.dataLiberacao!=null? new Date(moment(result.dataLiberacao).add(1, 'days').format("YYYY-MM-DD")): null,
        dataLiberacaoFim: result.dataLiberacaoFim!=null? new Date(moment(result.dataLiberacaoFim).add(1, 'days').format("YYYY-MM-DD")): null,
        liberadoPai: result.liberadoPai!=null? new Date(moment(result.liberadoPai).add(1, 'days').format("YYYY-MM-DD")): null
      })
      this.dataFim(result.dataLiberacao)

      this.notaService.avaliacaoPodeExcluir(this.id).subscribe(result=>{
        this.podeExcluir = result[0].qtd==0 ? true : false
      })

    })
  }

  dataFim(data: any){
    this.minDateEnd = data
  }

  excluir(){
    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente excluir essa avaliação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {
        this.notaService.deleteAvaliacao(this.id).subscribe(result =>{
          swal.fire({
            title: 'Sapiens',
            text: 'Avaliação excluída com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })
    
          setTimeout( () => {
              this.router.navigate(['/dashboard/notas/avaliacoes'])
            }, 2000 );
        })
      }
    })
  }

  gravar(avaliacao : Avaliacao){

    avaliacao.visivelBoletim = avaliacao.visivelBoletim ? 'S' : 'N'
    avaliacao.ano = this.loginService.retornarAno()

    if(this.id == undefined){
      this.save(avaliacao)
    }else{
      this.update(avaliacao)
    }

  }

  save(avaliacao : Avaliacao){
    
    this.notaService.createAvaliacao(avaliacao).subscribe(result=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Avaliação cadastrada com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      this.cadastroForm.reset()

    },(error)=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Ocorreu um erro no cadastro.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(avaliacao: Avaliacao) {

    avaliacao.id = this.id

    this.notaService.updateAvalicao(avaliacao).subscribe(result=>{
      
      swal.fire({
        title: 'Sapiens',
        text: 'Avaliação alterada com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/notas/avaliacoes'])
        }, 70 );
      },(error: HttpErrorResponse)=>{
    })
  }
}