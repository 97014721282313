import {Component, NgModule, OnInit, ViewChild, AfterViewInit} from '@angular/core'
import {Router} from '@angular/router'
import {BrowserModule} from '@angular/platform-browser'
import swal from 'sweetalert2';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlunoService } from '@shared/service/aluno.service';
import { LoginService } from '@shared/service/login.service';
import { Filtro } from '@shared/util/util.model';
import { UtilService } from '@shared/util/util.service';
import { AlunoGrid } from '@shared/model/aluno.model';

// @NgModule({
//   imports: [ BrowserModule]
// })

@Component({
  selector: 'app-aluno',
  templateUrl: './aluno.component.html',
  styleUrls: ['./aluno.component.scss']
})
export class AlunoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['status', 'nome', 'nomeTurma', 'turno', 'contratante', 'telefoneContratante', 'mensalidade', 'acao']
  dataSource: MatTableDataSource<AlunoGrid>;

  filter: string
  page: number
  pageSize: number

  constructor(private alunoService : AlunoService,
              private loginService : LoginService,
              private utilService : UtilService,
              private router: Router) { 

              }

  ngOnInit() {
    this.load()
  }

  doFilter(){ 
    if(this.filter!=undefined){
      this.dataSource.filter = this.filter.trim().toLocaleLowerCase()
      if(this.paginator.pageIndex>0 && this.dataSource.filteredData.length >0){
        if((this.dataSource.filteredData.length*2) < (this.paginator.pageSize*this.paginator.pageIndex)){
          this.paginator.pageIndex=0
          this.dataSource.filter = this.filter.trim().toLocaleLowerCase()
        }
      }
      this.filtroGravar()
    }
  }

  doPage(ev: any){
    this.page = ev.pageIndex
    this.pageSize = ev.pageSize
    this.filtroGravar()
  }
  
  filtroAplicar(){

    let filtroTmp: Filtro
    filtroTmp = this.utilService.retornaFiltro("_alunos")

    if(filtroTmp != null){

      if(filtroTmp.filtro!=undefined)
        this.filter = filtroTmp.filtro

      if(filtroTmp.pagina!=undefined)
        this.page = filtroTmp.pagina

      if(filtroTmp.qtdPagina!=undefined)
        this.pageSize = filtroTmp.qtdPagina
      
      this.paginator.pageIndex = this.page
      this.paginator.pageSize = this.pageSize
      
      this.doFilter()
    }

  }

  filtroGravar(){
    let filtro = new Filtro

    filtro.nome="_alunos"
    filtro.filtro = this.filter
    filtro.pagina = this.page
    filtro.qtdPagina = this.pageSize
    
    this.utilService.setaFiltro(filtro)
  }

  load(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunoService.obterAlunoListaGrid(this.loginService.retornarAno())
    .subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()

      this.filtroAplicar()
    })
  }

  update(idAluno: number){
    this.router.navigate(['dashboard/alunos/form',idAluno]);
  }

}