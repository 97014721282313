import { Component, OnDestroy, OnInit } from '@angular/core';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { NotaService } from '@shared/service/nota.service';
import { Turma } from 'src/app/shared/model/turma.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import { Observable, from, Subscription, Subject, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/shared/service/login.service';
import { Aluno } from 'src/app/shared/model/aluno.model';

@Component({
  selector: 'app-boletimimpressao',
  templateUrl: './boletimimpressao.component.html',
  styleUrls: ['./boletimimpressao.component.scss']
})
export class BoletimimpressaoComponent implements OnInit, OnDestroy {

  avaliacao:string[]= ['nome','id']

  columns: any[] = [{ columnDef: 'nome', header: 'Disciplina', cell: 'nomeDisciplina', size: '30', edicao: '0'}]
  displayedColumns: any
  dataSource: any

  idTurma: number
  idAluno: number

  nomeAluno: string
  nomeTurma: string

  alunosBoletim : any[] = []
  alunos: Aluno[]
  turmas: Turma[]
  
  ano: number
  nomeEscola:string
  id:number

  doc: jsPDF

  liberacao: boolean = false

  avaliacaoGrid: string[] = [] 
  
  subscription: Subscription = new Subscription();
	onDestroyer$ = new Subject()

  arrayImagens: any[] = []

  constructor(private turmaService: TurmaService,
              private alunoService: AlunoService,
              private loginService: LoginService,
              private notaService: NotaService) {
                 
                this.notaService.obterAvaliacoesBoletim(this.loginService.retornarAno())
                  .pipe(takeUntil(this.onDestroyer$)).subscribe(result=>{
                  let tamanho = (70/ result.length)
                  let x=0
                  result.forEach(element => {
                    let liberacao = 'N'
                      if(element.dataLiberacao!=null){
                      if(moment().format()>= moment(element.dataLiberacao).format()){
                        liberacao = 'S'
                      }
                    }
                    this.columns.push({ columnDef: element.id.toString()=="nome" ? element.id.toString() : x.toString(), header: element.nomeReduzido== undefined? element.nome : element.nomeReduzido, cell: element.id.toString()=="nome" ? element.id.toString() : x.toString(), size: tamanho, edicao: liberacao=='N' ?'0':'1' })
                    
                    if(element.id.toString()!="nome"){
                      x++
                    }
                  });
                  this.displayedColumns = this.columns.map(c => c.columnDef);
                })
              }

  ngOnInit() {
    const interval$ = new Observable<number>(subscriber => {
      let counter = 0;      
      const intervalId = setInterval(() => {
        subscriber.next(counter++);
      }, 1000);
      return () => clearInterval(intervalId);
    });

    const subscription = interval$.subscribe({
      next: value => console.log(value),
      complete: () => console.log('Completed')
    });

    setTimeout(() => {
      subscription.unsubscribe();
    }, 5000);

    // =============================================
    
    this.loginService.obterDadosEscola()
      .pipe(takeUntil(this.onDestroyer$)).subscribe(result=>{
      this.id = result.id
      this.nomeEscola = result.descricao
    })
    this.ano = this.loginService.retornarAno()

    this.turmaService.obterTurmaComboMensalidade(this.ano)
      .pipe(takeUntil(this.onDestroyer$)).subscribe(result => {
      this.turmas = result
    })
  }

  ngOnDestroy(): void {
		this.onDestroyer$.unsubscribe()
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
  
  capturaBoletim(id:number): Observable<any>{
    return from (html2canvas(document.getElementById('notas'+id)).then(function(canvas) {     
      return canvas.toDataURL("image/png");     
    }))
  } 

  capturaImagem(x: number){
      swal.fire({
        title: 'Lendo registro ' + (x+1) + ' de ' + this.alunosBoletim.length,
        allowOutsideClick: false
      });
      swal.showLoading();

      this.capturaBoletim(x).pipe(takeUntil(this.onDestroyer$)).subscribe(result=>{ 
        this.arrayImagens.push(result)        
        if(x < (this.alunosBoletim.length-1)){
          this.capturaImagem(x+1)
        } else {
          swal.fire({
            title: 'Gerando arquivo para impressão',
            allowOutsideClick: false
          });
          swal.showLoading();
          this.gerarPDF()
        }
      })
  }

  gerarPDF(){

      let doc = new jsPDF('p', 'mm', 'a4');
      let x=0
      this.arrayImagens.forEach(element => {
        if(x!=0){
          doc.addPage()
        }                           
        doc.setPage(x+1)
        if(this.arrayImagens.length == 1){
          doc.addImage(element, 'JPEG',5,5,211,135);
        } else {
          doc.addImage(element, 'JPEG',5,5,207,135);
        }
        x++
      });

      doc.save('Boletim ' + this.nomeTurma + '.pdf');
      swal.close()
      this.liberacao = true

  }
  
  impressao(){
      this.liberacao = false
      this.arrayImagens = []
      this.capturaImagem(0)
  }
  
  async retornaAlunos(){
    this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano)
      .pipe(takeUntil(this.onDestroyer$)).subscribe(async result =>{
      this.alunos = result
      this.idAluno = 0
      this.retornaNotas()
    })
  }

  async retornaNotas(){

    this.liberacao = false
    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunosBoletim = []

    if(this.idAluno==0){

      this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano)
        .pipe(takeUntil(this.onDestroyer$)).subscribe(async result =>{
        await result.forEach(element => {
          this.notaService.obterNotasAluno(element.id, this.ano)
            .pipe(takeUntil(this.onDestroyer$)).subscribe( retorno => {
            this.nomeTurma = retorno[0].nomeReduzido
            this.alunosBoletim.push(retorno)
          })   
        });

        swal.close()
        this.liberacao = true
      })
    } else {

      this.notaService.obterNotasAluno(this.idAluno, this.ano)
        .pipe(takeUntil(this.onDestroyer$)).subscribe( retorno => {
        this.nomeTurma = retorno.nomeReduzido
        this.alunosBoletim.push(retorno)
        swal.close()
        this.liberacao = true
      })

    }

  }
}