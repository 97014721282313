import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPaginatorIntl } from 'src/app/paginator';
import { TableComponent } from './table/table.component';
import * as fromComponents from './redux/components.reducer';

@NgModule({
    declarations: [
        TableComponent,
    ],
    imports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        StoreModule.forFeature('components', fromComponents.componentsReducer)
    ],
    exports: [
        TableComponent
    ],
    providers:[
        { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
    ]
})

export class ComponentsModule { }
