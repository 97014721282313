import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { TurmaService } from '@shared/service/turma.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-disciplina',
  templateUrl: './disciplina.component.html',
  styleUrls: ['./disciplina.component.scss']
})
export class DisciplinaComponent implements OnInit {

  idTurma: number
    
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['disciplina', 'nomeProfessor', 'nota']
  dataSource: MatTableDataSource<any>;
  
  constructor(private turmaService: TurmaService,
              private loginService: LoginService) { }

  ngOnInit() {
    this.load()
  }

  load(){
    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.turmaService.obterTurmaDisciplinaProfessor(this.idTurma, this.loginService.retornarAno())
    .subscribe(retorno=>{
        this.dataSource = new MatTableDataSource(retorno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        swal.close()
    })
  }
}
