import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { BACKEND_API } from "../../app.api";

import { map } from "rxjs/operators";
import { EmailMensalidade } from "../model/email.model";

@Injectable()

export class EmailService{

    constructor(private http: HttpClient){}

    enviarEmailGenerico(dados:any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/sendmail/emailgenerico`, dados)
    }

    enviarEmailMensalidade(tipo:number, mes:string, ano:number, teste:number): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/sendmail/emailmensalidade/${tipo}/${mes}/${ano}/${teste}`)
    }

    obterEmailMensalidade(tipo:number): Observable<EmailMensalidade> {
        return this.http.get<EmailMensalidade>(`${BACKEND_API}/emailmensalidade/${tipo}`)
    }

    updateEmailMensalidade(email: EmailMensalidade): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/emailmensalidade`, email)
        .pipe(map((result: any) => result))
    }
}