import { createSelector} from '@ngrx/store';

export const selectComponentsState = (state:any) => state.components;

export const loadTable = createSelector(
  selectComponentsState,
  table => table
);

export const loadPageName = createSelector(
  selectComponentsState,
  table => table.pageName
);
