import { Component, OnInit } from '@angular/core';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-editarboleto',
  templateUrl: './editarboleto.component.html',
  styleUrls: ['./editarboleto.component.scss']
})
export class EditarboletoComponent implements OnInit {

  cadastroForm: FormGroup

  constructor(private financeiroService:FinanceiroService,
              private fb: FormBuilder) { }

  ngOnInit() {
    
    this.cadastroForm = this.fb.group({
      multa: this.fb.control('', [Validators.required]),
      multaDiaria: this.fb.control('', [Validators.required]),
      mensagem: this.fb.control('', [Validators.required]),
      mensagemAtraso: this.fb.control('', [Validators.required]),
    })

    this.load()
  }

  gravar(cadastro: FormControl){
    let dados : any[] = []
    dados.push(cadastro)
    this.financeiroService.updateBoleto(dados).subscribe(result =>{
      swal.fire({
        title: 'Sapiens',
        text: 'Dados alterados com sucesso!',
        icon: 'info',
        showConfirmButton: false,
        timer: 3000
      })
    })
  }

  load(){
    this.financeiroService.obterBoletoMulta().subscribe(result=>{
      this.cadastroForm.patchValue({
        multa : result[0].multaMensalidade,
        multaDiaria : result[0].atrasoDia,
      })
      
      this.financeiroService.obterBoletoMensagem().subscribe(result=>{
        this.cadastroForm.patchValue({
          mensagem: result[0].mensagem,
          mensagemAtraso: result[1].mensagem
        })

      })  
    })
  }
}
