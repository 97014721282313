export class Mensalidade{
    id : number
    idAluno : number
    mes : number
    valor : number
    ano : number
    dataPagamento : Date
    idUsuario : number
    nomeUsuario: string
    cadastro : Date
    mensalidade : number
}

export class ListaAlunosTurmaMensalidade{
    id : number
    matricula : string
    nome : string
    jane : number
    feve : number
    marc : number
    abri : number
    maio : number
    junh : number
    julh : number
    agos : number
    sete : number
    outu : number
    nove : number
    deze : number
}