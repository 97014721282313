import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { Mensalidade } from "../../pages/financeiro/financeiro.model";

@Injectable()

export class FinanceiroService{

    constructor(private http: HttpClient){}

    boletoConfig(): Observable<any>{
        return this.http.get<any[]>(`${BACKEND_API}/transacao/boleto/dados`)
    }

    create(mensalidade: Mensalidade): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/financeiro/mensalidade/aluno`, mensalidade)
        .pipe(map((result: any) => result))
    }
    
    obterBoletoMensagem(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/boletos/dadosmensagem`)
    }

    obterBoletoMulta(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/boletos/dadosboleto`)
    }

    obterDatasPagamento(ano:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/financeiro/datapagamento/${ano}`)
    }
    
    obterExtrato(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/transacao/retornaextrato`)
    }

    obterMensalidadeListaAlunoTurma(idTurma:number, ano:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/financeiro/mensalidade/alunolistamensalidade/${idTurma}/${ano}`)
    }

    obterMensalidadeIdAluno(idAluno:number, ano:number): Observable<Mensalidade[]> {
        return this.http.get<Mensalidade[]>(`${BACKEND_API}/financeiro/mensalidade/aluno/${idAluno}/${ano}`)
    }  

    obterMesesBoleto(ano:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/transacao/retornamesesboleto/${ano}`)
    }  

    alunoTurma(idAluno:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunoturma/${idAluno}/${ano}`)
    }  

    gerarBoleto(idAlunos: any[], mes:number, ano:number): Observable<any>{

        return this.http.post<any>(`${BACKEND_API}/transacao/boleto/${mes}/${ano}`, idAlunos)
        .pipe(map((result: any) => result))
    }

    obterMensalidadeConsolidadaMes(mes: number, ano: number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/financeiro/mensalidadeconsolidadomes/${mes}/${ano}`)
    }   

    obterMensalidadePagaConsolidada(ano: number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/financeiro/mensalidadepagaconsolidada/${ano}`)
    }   

    obterPagamentosBoletoCartaoMes(mes:number, ano:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/transacao/retornapagamentoboleto/${mes}/${ano}`)
    } 

    obterTransferenciaBancaria(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/transferenciabancaria`)
    } 

    obterValorComJuros(idTurma: number, ano: number, datas: any[]): Observable<any[]> {
        
        return this.http.post<any[]>(`${BACKEND_API}/valor/juros/${idTurma}/${ano}`, datas)
    } 

    transferencia(valor: any): Observable<any[]> {
        
        return this.http.post<any[]>(`${BACKEND_API}/transacao/transferencia`, {'valor': valor.toString().replace(".",",")})
    } 
    
    validarPagamentos(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/transacao/validapagamento`)
    } 
    
    update(turma: Mensalidade): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/turmas`, turma)
        .pipe(map((result: any) => result))
    }

    updateBoleto(dados: any[]): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/boletos/update`, dados)
        .pipe(map((result: any) => result))
    }
}