import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { LoginService } from 'src/app/shared/service/login.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface gerarBoletoDialogData {
  id: number
  ano: number
  vencimento: any
  idTurma: number
}

@Component({
  selector: 'app-boletocartao',
  templateUrl: './boletocartao.component.html',
  styleUrls: ['./boletocartao.component.scss']
})
export class BoletocartaoComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['status', 'nome', 'nomeTurma', 'turno', 'valor', 'gerado', 'pago', 'tipo', 'acao']
  dataSource: MatTableDataSource<any>;

  ano: number 
  meses: any[] = []
  mesSelecionado : number
  cboFiltro: string = "T"
  novoBoleto: boolean
  dataVencimento: any

  constructor(private financeiroService : FinanceiroService,
              private loginService: LoginService,
              public dialog: MatDialog) { 
                this.ano = this.loginService.retornarAno()
              }

  ngOnInit() {
    this.financeiroService.obterMesesBoleto(this.ano).subscribe(result=>{
      if(result.length>0){
        moment.locale('pt-br')
        this.mesSelecionado = result[0].mes
        result.forEach(element => {
          this.meses.push({'id':element.mes, 'nome':moment(this.ano + "-" + element.mes +"-2").format('MMMM')})  
    
        });
      }
      this.load()
    })
  }

  atualizar(){
    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente atualizar os pagamentos?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {

      if (retorno.value) {
        swal.fire({
          title: 'Atualizando... Aguarde!'
        });
        swal.showLoading();
        
        this.financeiroService.validarPagamentos().subscribe(result=>{
          swal.fire({
            title: 'Sapiens',
            text: 'Pagamentos atualizados!',
            icon: 'info',
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout( () => {
            this.load()
          }, 2000 );
        })
      }
    })
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  gerarNovoBoleto(id:number, idTurma:number, url:string){
    swal.fire({
      title: 'Sapiens',
      text: 'Selecione uma opção',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Novo Boleto",
      cancelButtonText: "Abrir último"
    })
    .then(retorno => {
      if (retorno.value) {
        this.openDialog(id, idTurma)
      }else{
        window.open(url, "_blank");
      }
    })

  }

async load(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();
    let dados = []

    await this.financeiroService.obterPagamentosBoletoCartaoMes(this.mesSelecionado, this.ano).subscribe(result=>{
      let idAluno = 0
      result.forEach(element => {
        if(idAluno!=element.idAluno){
          if(this.cboFiltro=='T'){
            dados.push(element)
          }else if(this.cboFiltro=='P' && element.status == 'paid'){
            dados.push(element)
          }else if(this.cboFiltro=='N' && (element.status == 'waiting_payment' || element.status == 'processing')){
            dados.push(element)
          }else if(this.cboFiltro=='C' && element.status == 'refused'){
            dados.push(element)
          }
          idAluno = element.idAluno
        }
      });

      this.vencimento()

      this.dataSource = new MatTableDataSource(dados);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      swal.close()
    })
  }
    
  openDialog(id:number, idTurma:number): void {
    const dialogRef = this.dialog.open(GerarNovoBoletoDialog, {
      width: '400px',
      height: '207px',
      data:{ id : id, vencimento : this.dataVencimento, idTurma : idTurma, ano: this.ano}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  vencimento(){
    this.financeiroService.obterDatasPagamento(this.ano).subscribe(result=>{
      result.forEach(element => {
        if(element.mes == this.mesSelecionado){
          this.dataVencimento = element.dataLimite
          if(moment(element.dataLimite).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')){
            this.novoBoleto=true
          } else {
            this.novoBoleto=false
          }
        }
      });
    })
  }
}

@Component({
  selector: 'gerarnovoboleto',
  templateUrl: './gerarnovoboleto.html',
})

export class GerarNovoBoletoDialog {

  vencimento: any
  novoVencimento: any
  valor: any
  idTurma: number
  dataMinima: Date = new Date(moment().add(1,"days").format("YYYY-MM-DD"))

  constructor(private financeiroService: FinanceiroService,
              public dialogRef: MatDialogRef<GerarNovoBoletoDialog>,
              @Inject(MAT_DIALOG_DATA) public data: gerarBoletoDialogData) {
                
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  gerarBoleto(){

    let selecionados : any[] = []

    selecionados.push({ 'id' : this.data.id, 'vencimento' : moment(this.novoVencimento).format('YYYY-MM-DD'), 'mensalidade': this.valor})

    this.financeiroService.gerarBoleto(selecionados, Number(moment(this.novoVencimento).format('MM')), this.data.ano).subscribe(result=>{

    })

    swal.fire({
      title: 'Sapiens',
      text: 'Boleto gerado com sucesso!',
      icon: 'warning',
      showConfirmButton: false,
      timer: 3000
    })
          
    this.onNoClick()
  }

  obterValor(){
    let datas : any[] = []
    
    datas.push({"vencimento": this.data.vencimento, "novoVencimento": moment(this.novoVencimento).format("YYYY-MM-DD")})

    this.financeiroService.obterValorComJuros(this.data.idTurma, Number(moment(this.data.vencimento).format('YYYY')), datas).subscribe(result=>{
      this.valor = JSON.stringify(result["valor"])
    })
  }

}