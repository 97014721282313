import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Usuario, AlterarSenha } from '../login/usuario.model';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BACKEND_API } from '@app/app.api';

@Component({
  selector: 'app-alterarsenha',
  templateUrl: './alterarsenha.component.html'
})
export class AlterarsenhaComponent implements OnInit {

  alteraSenhaForm: FormGroup
  validacao: any
  
  constructor(private http:HttpClient,
              private router: Router,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.alteraSenhaForm = this.fb.group({
      password: this.fb.control(''),
      new_password: this.fb.control(''),
      confirm_password: this.fb.control('')
    })
  }

  updatePassword(alterarSenha: AlterarSenha){

    let usuario: Usuario =JSON.parse(window.localStorage.getItem("sapiens"))
    alterarSenha.email = usuario.email

    this.http.put<any>(`${BACKEND_API}/usuarios/update/password`, alterarSenha) 
      .subscribe(res => {

      switch (res["message"]){

        case "Senha precisa ter: uma letra maiúscula, uma letra minúscula, um número, um caractere especial(@#$%) e tamanho entre 6-12":
        swal.fire({
          title: 'Sapiens',
          text: 'A senha deve conter ao menos 6 caracteres, um número, uma letra maiúscula e uma minúscula.',
          icon: 'error',
          showConfirmButton: false,
          timer: 4000
        })            
        break
        
        case "Senha incorreta.":
          swal.fire({
            title: 'Sapiens',
            text: 'Senha incorreta!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          })            
          break

        case "Novas senhas não conferem.":
          swal.fire({
            title: 'Sapiens',
            text: 'Novas senhas não conferem!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          })            
          break

        case "Senha alterada.":
          swal.fire({
            title: 'Sapiens',
            text: 'Senha alterada!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout( () => {
            this.router.navigate(['/dashboard/'])
          }, 1400 );            
          break                        
      }
    })
  }  
}