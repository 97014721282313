import { Component, OnInit } from '@angular/core';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { Turma } from 'src/app/shared/model/turma.model';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { Mensalidade } from '../financeiro.model';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { LoginService } from 'src/app/shared/service/login.service';
import { AlunoAutoComplete } from 'src/app/shared/model/aluno.model';

@Component({
  selector: 'app-recebimentomensalidade',
  templateUrl: './recebimentomensalidade.component.html',
  styleUrls: ['./recebimentomensalidade.component.scss']
})
export class RecebimentomensalidadeComponent implements OnInit {
 
  mensalidadeForm : FormGroup
  aluno : AlunoAutoComplete[]
  turma : Turma[]
  mensalidades : Mensalidade[]

  matriculaAluno:string = undefined

  idAluno:number = 0

  teste: boolean = false

  mensalidade : Mensalidade
  valorPagamento: number
  dataPagamento: Date

  constructor(private alunoService: AlunoService,
              private loginService : LoginService,
              private turmaService: TurmaService,
              private financeiroService: FinanceiroService,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute) { 
                this.matriculaAluno = this.activatedRoute.snapshot.params['matricula']==undefined ? undefined : this.activatedRoute.snapshot.params['matricula']
              }

  ngOnInit() {

    this.mensalidadeForm = this.fb.group({
      idTurma: this.fb.control(''),
      nome: this.fb.control('', [Validators.required])
    })

    this.turmaService.obterTurmaComboMensalidade(this.loginService.retornarAno()).subscribe(result => {
      this.turma = result
    })

    if(this.matriculaAluno != undefined){
      this.alunoService.obterAlunoMatriculaAno(this.matriculaAluno, this.loginService.retornarAno()).subscribe(result => {

        this.mensalidadeForm.patchValue({
          idTurma: result[0].idTurma
        })
        
        this.retornaAlunos(result[0].idTurma)

        this.mensalidadeForm.patchValue({
          nome: result[0].id
        })

        this.retornaPagamentos(result[0].id)

      })

    }
  }

  retornaAlunos(id:number){

    this.mensalidadeForm.patchValue({
      nome: ''
    })

    this.idAluno = undefined
    this.mensalidades = undefined

    this.alunoService.obterAlunoComboTurma(id,this.loginService.retornarAno()).subscribe(result => {
      this.aluno = result
    })

  }

  retornaPagamentos(idAluno:number){

    this.mensalidades = undefined
    
    this.idAluno = idAluno
    this.financeiroService.alunoTurma(idAluno,this.loginService.retornarAno()).subscribe(result => {
      this.mensalidade = result.mensalidade
      this.valorPagamento = result.mensalidade
      
      this.financeiroService.obterMensalidadeIdAluno(idAluno,this.loginService.retornarAno()).subscribe(result => {
        this.mensalidades = result
      })
    })
  }

  salvar(mes:any){

    moment.locale('pt-BR');
    let mensalidadeGravar: Mensalidade = new Mensalidade

    mensalidadeGravar.idAluno = this.idAluno
    mensalidadeGravar.mes = mes
    mensalidadeGravar.valor = this.valorPagamento
    mensalidadeGravar.ano = this.loginService.retornarAno()
    mensalidadeGravar.dataPagamento = this.dataPagamento

    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente cadastrar o pagamento no valor de R$' + this.valorPagamento.toFixed(2) + ', referente a mensalidade do mês de ' + 
            moment(mensalidadeGravar.ano + "/" + mensalidadeGravar.mes + "/01").format('MMMM') + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {

      if (retorno.value) {

        this.financeiroService.create(mensalidadeGravar).subscribe(result=>{
          this.dataPagamento=undefined
          swal.fire({
            title: 'Sapiens',
            text: 'Mensalidade cadastrada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.retornaPagamentos(this.idAluno)
          },(error: HttpErrorResponse)=>{
        })

      }
    })
  }
}