import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from '@shared/service/login.service';
import swal from 'sweetalert2';
import { AdministracaoService } from '@shared/service/administracao.service';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { MensagemService } from '@shared/service/mensagem.service';
import { AcessoMenu } from '@shared/model/administracao.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  pusher: any
  acessos: AcessoMenu[] = []
  @Input() qtdMensagem: number
  loggedIn: boolean
  abrirAnoVisivel: boolean = true
  idUsuario: number = undefined

  constructor( private loginService: LoginService, 
               private mensagemService : MensagemService,
               private fianceiroService: FinanceiroService,
               private administracaoService: AdministracaoService) { 

    this.loginService.returnId().subscribe(result=>{
      this.idUsuario = Number(result.id)
    })

    this.loggedIn = this.isloggedin()
    this.administracaoService.obterAcessoMenu().subscribe(result=>{
      this.acessos=result

      // this.fianceiroService.boletoConfig().subscribe(result=>{
      //   if(result.length==0){
      //     let index:number = this.acessos.findIndex(result=> result.id === 36)
      //     this.acessos[index].visivel="N"
      //     index = this.acessos.findIndex(result=> result.id === 37)
      //     this.acessos[index].visivel="N"
      //     index = this.acessos.findIndex(result=> result.id === 38)
      //     this.acessos[index].visivel="N"
      //   }
      // })

    })

  }

  ngOnInit() {
    this.load()
    // console.log(pusherChannel);    
    // let channel = this.pusher.subscribe(pusherChannel);
    // channel.bind('test_event', data => {
      
    //   data.para.forEach(element => {

    //     if(element.funcionarioId == this.idUsuario){

    //       swal.fire({
    //         title: 'Sapiens',
    //         text: 'Nova mensagem de ' + data.name + ".",
    //         icon: 'info',
    //         showConfirmButton: false,
    //         timer: 2000
    //       })
    
    //       this.verificaMensagem()
    //     }
    //   });

    // })   

  }

  isloggedin(){
    return this.loginService.isLoggedIn()
  }

  load(){
    // this.mensagemService.obterMensagensNaoLidas().subscribe(result => {
    //   this.qtdMensagem = result.length
    // })
  }
  
  logout() {

    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente sair do sistema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(willDelete => {
      if (willDelete.value) {
        this.loginService.logout()
        return this.loginService.usuario()
      }
    });
  }  

  reciverFeedback(tmp: number) {
    console.log('Foi emitido o evento e chegou no pai >>>> ', tmp);
  }

  verificaMensagem(){
    this.qtdMensagem = this.qtdMensagem + 1
  }
}