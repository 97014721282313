import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleCrypt } from 'ngx-simple-crypt';
import swal from 'sweetalert2';
import { crypt } from '@app/app.api';
import { LoginService } from '@shared/service/login.service';

@Component({
  selector: 'app-alterarano',
  templateUrl: './alterarano.component.html',
  styleUrls: ['./alterarano.component.scss']
})
export class AlteraranoComponent implements OnInit {

  anos: any[]
  ano: number
  simpleCrypt = new SimpleCrypt();

  constructor(private loginService: LoginService,
              private router: Router) { }

  ngOnInit() {
    this.loginService.obterAnos().subscribe(result=>{

      this.anos = result
      
      if(sessionStorage.getItem("main")==null){
        this.ano = result[0].ano
        sessionStorage.setItem("main",JSON.stringify({"ano": this.simpleCrypt.encode(crypt, this.ano.toString())}))
      } else {
        let dadosMain = JSON.parse(sessionStorage.getItem("main"))
        this.ano = Number(this.simpleCrypt.decode(crypt, dadosMain.ano))
      }

    })
  }
  
  setaAno(){
    this.loginService.setaAno(this.ano.toString())
    swal.fire({
      title: 'Sapiens',
      text: 'Ano letivo alterado para ' + this.ano + '!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500
    })
  }

}
