import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FuncionarioService } from '@shared/service/funcionario.service';
import { FuncionarioGrid } from '@shared/model/funcionario.model';

@Component({
  selector: 'app-funcionario',
  templateUrl: './funcionario.component.html',
  styleUrls: ['./funcionario.component.scss']
})
export class FuncionarioComponent implements OnInit {

  displayedColumns = ['status', 'nome', 'nomeTipoFuncionario', 'telefone', 'acao']
  dataSource: MatTableDataSource<FuncionarioGrid>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private alunoService : FuncionarioService,
              private router: Router) { }

  ngOnInit() {
    this.load()
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }
  
  load(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunoService.obterFuncionarioListaGrid()
    .subscribe(retorno => {

      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    
    })
  }

  update(idFuncionario: number){
    this.router.navigate(['dashboard/funcionarios/form',idFuncionario]);
  }

}