import { Component, OnInit } from '@angular/core';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { NotaService } from '@shared/service/nota.service';
import { Turma } from 'src/app/shared/model/turma.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { Aluno } from 'src/app/shared/model/aluno.model';

@Component({
  selector: 'app-fichaindividual',
  templateUrl: './fichaindividual.component.html',
  styleUrls: ['./fichaindividual.component.scss']
})
export class FichaindividualComponent implements OnInit {
  avaliacao:string[]= ['nome','id']

  columns: any[] = [{ columnDef: 'nome', header: 'Disciplina', cell: 'nomeDisciplina', size: '30', edicao: '0'}]
  displayedColumns: any
  dataSource: any

  idTurma: number
  idAluno: number

  nomeAluno: string
  nomeTurma: string

  alunosBoletim : any[] = []
  alunos: Aluno[]
  turmas: Turma[]
  
  ano: number
  nomeEscola:string
  id:number

  doc: string // = "http://backend.sapienseducacional.com.br/uploads/1/docs/raw.docx"

  liberacao: boolean = false

  avaliacaoGrid: string[] = [] 
  
  constructor(private turmaService: TurmaService,
              private alunoService: AlunoService,
              private loginService: LoginService,
              private notaService: NotaService) {
                 
                // this.notaService.obterAvaliacoesBoletim(this.loginService.retornarAno())
                // .subscribe(result=>{
                //   let tamanho = (70/ result.length)
                //   result.forEach(element => {
                //     let liberacao = 'N'
                //     if(element.dataLiberacao!=null){
                //       if(moment().format()>= moment(element.dataLiberacao).format()){
                //         liberacao = 'S'
                //       }
                //     }
                //     this.columns.push({ columnDef: element.id.toString(), header: element.nomeReduzido== undefined? element.nome : element.nomeReduzido, cell:element.id.toString(), size: tamanho, edicao: liberacao=='N' ?'0':'1' })
                //   });
                //   this.displayedColumns = this.columns.map(c => c.columnDef);
                // })
              }

  ngOnInit() {
    
    this.loginService.obterDadosEscola().subscribe(result=>{
      this.id = result.id
      this.nomeEscola = result.descricao
    })
    this.ano = this.loginService.retornarAno()

    this.turmaService.obterTurmaCombo(this.ano).subscribe(result => {
      this.turmas = result
    })
  }

  async impressao(){
    this.liberacao = false
    this.doc = ""

    swal.fire({
      title: 'Gerando arquivos',
      allowOutsideClick: false
    });
    swal.showLoading();

    this.notaService.gerarFichaIndividual(this.alunosBoletim).subscribe(result=> {
      setTimeout( () => {

        if(this.idAluno == 0){
          this.doc = "http://backend.sapienseducacional.com.br/uploads/1/docs/" + this.alunosBoletim[0][0].nomeReduzido + " - " + this.alunosBoletim[0][0].ano + ".docx"
        } else {
          this.doc = "http://backend.sapienseducacional.com.br/uploads/1/docs/" + this.alunosBoletim[0][0].nome + " - " + this.alunosBoletim[0][0].ano + ".docx"
        }

        swal.close()
        this.liberacao = true
      }, 6000 ); 
    })

  }

  async retornaAlunos(){
    this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano).subscribe(async result =>{
      this.idAluno = 0
      this.alunos = result
      this.retornaNotas()
    })
  }

  retornaNotas(){

    this.liberacao = false
    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunosBoletim = []

    if(this.idAluno==0){

      this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano).subscribe(async result =>{
        
        await result.forEach(element => {

          this.notaService.obterNotasAluno(element.id, this.ano).subscribe( retorno => {
            this.nomeTurma = retorno[0].nomeReduzido
            this.alunosBoletim.push(retorno)
          })

        });
        
        setTimeout( () => {
          swal.close()
          this.liberacao = true
        }, 2000 ); 

      })

    } else {

      this.notaService.obterNotasAluno(this.idAluno, this.ano)
      .subscribe( retorno => {
        this.nomeTurma = retorno.nomeReduzido
        this.alunosBoletim.push(retorno)
        swal.close()
        this.liberacao = true
      })

    }

  }
}