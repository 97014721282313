import { Component, OnInit } from '@angular/core';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { Chart } from 'chart.js'
import swal from 'sweetalert2';

@Component({
  selector: 'app-faturamentomensal',
  templateUrl: './faturamentomensal.component.html',
  styleUrls: ['./faturamentomensal.component.scss']
})
export class FaturamentomensalComponent implements OnInit {

  chart: any = undefined;

  ano: number
  consolidadoPagamento : any[] = []
  consolidadoMes : any[] = []
  atrasoMes : any[] = []
  arrayMes: any[] = [1,2,3,4,5,6,7,8,9,10,11,12]
  
  somaPago: number = 0
  somaDevido:number = 0

  stepSize: any = 0

  constructor(private financeiroService: FinanceiroService,
              private loginService : LoginService) {
                swal.fire({
                  title: 'Carregando dados'
                });
                swal.showLoading();
               }

  ngOnInit() {
    this.ano = this.loginService.retornarAno()

    this.financeiroService.obterMensalidadePagaConsolidada(this.ano).subscribe(result=>{

      this.consolidadoPagamento.push(result[0]['jane'])        
      this.consolidadoPagamento.push(result[0]['feve'])  
      this.consolidadoPagamento.push(result[0]['marc'])  
      this.consolidadoPagamento.push(result[0]['abri'])  
      this.consolidadoPagamento.push(result[0]['maio'])  
      this.consolidadoPagamento.push(result[0]['junh'])  
      this.consolidadoPagamento.push(result[0]['julh'])        
      this.consolidadoPagamento.push(result[0]['agos'])  
      this.consolidadoPagamento.push(result[0]['sete'])  
      this.consolidadoPagamento.push(result[0]['outu'])  
      this.consolidadoPagamento.push(result[0]['nove'])  
      this.consolidadoPagamento.push(result[0]['deze'])

      this.financeiroService.obterMensalidadeConsolidadaMes(1,this.ano).subscribe(result=>{
        this.consolidadoMes.push(result.total)
        
        this.financeiroService.obterMensalidadeConsolidadaMes(2,this.ano).subscribe(result=>{
          this.consolidadoMes.push(result.total)

          this.financeiroService.obterMensalidadeConsolidadaMes(3,this.ano).subscribe(result=>{
            this.consolidadoMes.push(result.total)

            this.financeiroService.obterMensalidadeConsolidadaMes(4,this.ano).subscribe(result=>{
              this.consolidadoMes.push(result.total)

              this.financeiroService.obterMensalidadeConsolidadaMes(5,this.ano).subscribe(result=>{
                this.consolidadoMes.push(result.total)

                this.financeiroService.obterMensalidadeConsolidadaMes(6,this.ano).subscribe(result=>{
                  this.consolidadoMes.push(result.total)
                  
                  this.financeiroService.obterMensalidadeConsolidadaMes(7,this.ano).subscribe(result=>{
                    this.consolidadoMes.push(result.total)

                    this.financeiroService.obterMensalidadeConsolidadaMes(8,this.ano).subscribe(result=>{
                      this.consolidadoMes.push(result.total)

                      this.financeiroService.obterMensalidadeConsolidadaMes(9,this.ano).subscribe(result=>{
                        this.consolidadoMes.push(result.total)

                        this.financeiroService.obterMensalidadeConsolidadaMes(10,this.ano).subscribe(result=>{
                          this.consolidadoMes.push(result.total)

                          this.financeiroService.obterMensalidadeConsolidadaMes(11,this.ano).subscribe(result=>{
                            this.consolidadoMes.push(result.total)

                            this.financeiroService.obterMensalidadeConsolidadaMes(12,this.ano).subscribe(result=>{
                              this.consolidadoMes.push(result.total)
                              
                              for (let index = 0; index < 12; index++) {
                                this.atrasoMes.push(this.consolidadoMes[index] - this.consolidadoPagamento[index])
                                
                                if(this.consolidadoPagamento[index]!=0){
                                  this.somaPago += this.consolidadoPagamento[index]
                                  this.somaDevido += (this.consolidadoMes[index] - this.consolidadoPagamento[index])
                                }
                                //this.atrasoMes.push(this.consolidadoPagamento[index]==0?0:(this.consolidadoMes[index] - this.consolidadoPagamento[index]))
                              }

                              this.chartValores()
                            })    
                          })  
                        })
                      })
                    })
                  })
                })
              })
            }) 
          })
        })
      })  
    })  
    
  }

  chartValores(){

    if(this.chart !== undefined){
      this.chart.destroy()
    }

    var canvas = <HTMLCanvasElement> document.getElementById("myChart")
    var ctx = canvas.getContext("2d")

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          data:
            this.consolidadoMes,
          backgroundColor: 
            '#9ad0f5',
          borderColor : 
            '#36a2eb',
            label: 'Valor a receber',
            fill: 'false'
        },{
          data:
            this.consolidadoPagamento,
          backgroundColor: 
            '#a5dfdf',
          borderColor : 
            '#4bc0c0',
            label: 'Valor recebido',
            fill: 'false'
        },{
          data:
            this.atrasoMes,
          backgroundColor: 
            '#ffb1c1',
          borderColor : 
            '#ff6384',
            label: 'Valor em atraso',
            fill: 'false'
        }],
        labels: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"]
      },
      options : {
        responsive: true,
        title: {
          display: false,
          text: 'Chart.js Line Chart'
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        maintainAspectRatio: false,
        spanGaps: false,
        elements: {
          line: {
            tension: 0.0001
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: false
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Valores em Reais'
            },
            ticks: {
              stepSize: this.stepSize
            }
          }]
        },
        plugins: {
          filler: {
            propagate: false
          },
          'samples-filler-analyser': {
            target: 'chart-analyser'
          }
        }
      }
    }) 
    swal.close()
  }

}