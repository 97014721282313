export class MensagemInterna {
    descricao: string
    corpo: string
    para: Para[]

    constructor(para: Para[], descricao: string, corpo: string) {
        this.para = para
        this.descricao = descricao
        this.corpo = corpo
    }
}

export class Para {
    idFuncionario: number

    constructor(id: number) {
        this.idFuncionario = id
    }
}