import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { AdministracaoService } from 'src/app/shared/service/administracao.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-abrirano',
  templateUrl: './abrirano.component.html',
  styleUrls: ['./abrirano.component.scss']
})
export class AbriranoComponent implements OnInit {

  ano: number = Number(moment().add(1, "year").format("YYYY"))
  
  constructor(private router: Router,
              private administracaoService : AdministracaoService) {

   }

  ngOnInit() {
  }

  abrirAno(){
    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente abrir o ano letivo de ' + this.ano + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(willDelete => {
      if (willDelete.value) {
        this.administracaoService.abrirAnoLetivo(this.ano).subscribe(result=>{
          swal.fire({
            title: 'Sapiens',
            text: 'Ano letivo aberto!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })

          setTimeout( () => {
            this.router.navigate(['/dashboard'])
          }, 2000 );

        })
      }
    })
  }
}
