import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppComponent } from "./app.component";
import { AlterarsenhaComponent } from "@pages/security/alterarsenha/alterarsenha.component";
import { AuthInterceptor } from "@pages/security/auth.interceptor";
import { DashboardComponent } from "@pages/dashboard/dashboard.component";
import { FooterComponent } from "@pages/footer/footer.component";
import { HomeComponent } from "@pages/home/home.component";
import { LoginComponent } from "@pages/security/login/login.component";
import { MenuComponent } from "@pages/menu/menu.component";
import { NotFoundComponent } from "@pages/not-found/not-found.component";
import { RedefinirsenhaComponent } from "@pages/security/redefinirsenha/redefinirsenha.component";
import { UsuarioComponent } from "@pages/usuario/usuario.component";
import { NgxPaginationModule } from "ngx-pagination"
import { NgxMaskModule } from "ngx-mask"
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxHmCarouselModule } from "ngx-hm-carousel";
import "hammerjs";

//---=== service ===---
import { LoginService } from "./shared/service/login.service";
import { LoggedInGuard } from "@pages/security/loggedin.guard";
import { EsqueciSenhaComponent } from "@pages/security/esqueci-senha/esqueci-senha.component";
import { LayoutModule } from "@angular/cdk/layout";
import { UtilService } from "./shared/util/util.service";
import { NumeroService } from "./shared/numero/numero.service";
import { CepService } from "./shared/cep/cep.service";
import { AlunoComponent } from "@pages/aluno/aluno.component";
import { FormalunoComponent, UploadFotoDialog, Rematricula } from "@pages/aluno/formaluno/formaluno.component";
import { FuncionarioComponent } from "@pages/funcionario/funcionario.component";
import { FormfuncionarioComponent, UploadFotoFuncionarioDialog } from "@pages/funcionario/formfuncionario/formfuncionario.component";
import { TurmaComponent, DetalheTurma } from "@pages/turma/turma.component";
import { FormturmaComponent } from "@pages/turma/formturma/formturma.component";
import { ExtracurricularComponent } from "@pages/extracurricular/extracurricular.component";
import { FormextracurricularComponent } from "@pages/extracurricular/formextracurricular/formextracurricular.component";
import { AlunoService } from "./shared/service/aluno.service";
import { getPaginatorIntl } from "./paginator";
import { TurmaService } from "./shared/service/turma.service";
import { CropimageComponent } from "@pages/cropimage/cropimage.component";
import { FuncionarioService } from "./shared/service/funcionario.service";
import { MensalidadeComponent } from "@pages/financeiro/mensalidade/mensalidade.component";
import { AboutComponent } from "@pages/about/about.component";
import { FinanceiroService } from "./shared/service/financeiro.service";
import { FaturamentomensalComponent } from "@pages/financeiro/faturamentomensal/faturamentomensal.component";
import { InadimplenteComponent } from "@pages/financeiro/inadimplente/inadimplente.component";
import { FaturamentodiaComponent } from "@pages/financeiro/faturamentodia/faturamentodia.component";
import { RecebimentomensalidadeComponent } from "@pages/financeiro/recebimentomensalidade/recebimentomensalidade.component";
import { EmailmensalidadeComponent } from "@pages/email/emailmensalidade/emailmensalidade.component";
import { EmailvencimentoComponent } from "@pages/email/emailvencimento/emailvencimento.component";
import { EmailinadimplentesComponent } from "@pages/email/emailinadimplentes/emailinadimplentes.component";
import { EmailService } from "./shared/service/email.service";
import { TurmadetalheComponent } from "@pages/turma/turmadetalhe/turmadetalhe.component";
import { DisciplinaComponent } from "@pages/turma/disciplina/disciplina.component";
import { FormdisciplinaComponent } from "@pages/turma/disciplina/formdisciplina/formdisciplina.component";
import { EmailmensagemComponent } from "@pages/email/emailmensagem/emailmensagem.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AvaliacaoComponent } from "@pages/notas/avaliacao/avaliacao.component";
import { NotaComponent } from "@pages/notas/nota/nota.component";
import { BoletimComponent } from "@pages/notas/boletim/boletim.component";
import { FormavaliacaoComponent } from "@pages/notas/avaliacao/formavaliacao/formavaliacao.component";
import { NotaService } from "./shared/service/nota.service";
import { BoletimimpressaoComponent } from "@pages/impressao/boletimimpressao/boletimimpressao.component";
import { ConselhoclasseComponent } from "@pages/documento/conselhoclasse/conselhoclasse.component";
import { AcessoComponent } from "@pages/administracao/acesso/acesso.component";
import { AdministracaoService } from "./shared/service/administracao.service";
import { UploadBannerDialog, BannerComponent } from "@pages/banner/banner.component";
import { BannerService } from "@pages/banner/banner.service";
import { GeracaoboletoComponent } from "@pages/financeiro/geracaoboleto/geracaoboleto.component";
import { BoletocartaoComponent, GerarNovoBoletoDialog } from "@pages/financeiro/boletocartao/boletocartao.component";
import { ExtratobancarioComponent } from "@pages/financeiro/extratobancario/extratobancario.component";
import { TransacoesComponent } from "@pages/financeiro/transacoes/transacoes.component";
import { EditarboletoComponent } from "@pages/financeiro/editarboleto/editarboleto.component";
import { MensagemService } from "./shared/service/mensagem.service";
import { EmailinternoComponent, EnviarEmailInterno, LerEmailInterno } from "@pages/emailinterno/emailinterno.component";
import { AbriranoComponent } from "@pages/administracao/abrirano/abrirano.component";
import { FichaindividualComponent } from "@pages/impressao/fichaindividual/fichaindividual.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { AlteraranoComponent } from "@pages/alterarano/alterarano.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { ToolbarComponent } from "@pages/toolbar/toolbar.component";
import { AtaconselhoclasseComponent } from "@pages/impressao/ataconselhodeclasse/ataconselhodeclasse.component";
import { MeusdadosComponent } from "@pages/meusdados/meusdados.component";
import { CnpjPipe } from "./shared/pipes/cnpj.pipe";
import { MaterialModule } from "./material.module";
import { DiarioclasseComponent } from "@pages/impressao/diarioclasse/diarioclasse.component";
import { ComponentsModule } from "./components/components.module";
import { EffectsModule } from "@ngrx/effects"
import { StoreRouterConnectingModule } from "@ngrx/router-store"
import { reducers, metaReducers } from "./reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools"
import { StoreModule } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { DiarioService } from "./shared/service/diario.service";
import { AlunoPresencaService } from "./shared/service/alunopresenca.service";

registerLocaleData(localePt, "pt");

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
    declarations: [
        AboutComponent,
        AcessoComponent,
        AlteraranoComponent,
        AlterarsenhaComponent,
        AlunoComponent,
        AvaliacaoComponent,
        AppComponent,
        BannerComponent,
        BoletimComponent,
        BoletimimpressaoComponent,
        BoletocartaoComponent,
        CnpjPipe,
        ConselhoclasseComponent,
        CropimageComponent,
        DashboardComponent,
        DetalheTurma,
        DiarioclasseComponent,
        DisciplinaComponent,
        EmailinadimplentesComponent,
        EmailmensagemComponent,
        EmailmensalidadeComponent,
        EmailvencimentoComponent,
        EnviarEmailInterno,
        EsqueciSenhaComponent,
        ExtracurricularComponent,
        ExtratobancarioComponent,
        FaturamentodiaComponent,
        FaturamentomensalComponent,
        FormalunoComponent,
        FormavaliacaoComponent,
        FormdisciplinaComponent,
        FormextracurricularComponent,
        FormfuncionarioComponent,
        FormturmaComponent,
        FooterComponent,
        FuncionarioComponent,
        GeracaoboletoComponent,
        GerarNovoBoletoDialog,
        HomeComponent,
        InadimplenteComponent,
        LerEmailInterno,
        LoginComponent,
        MensalidadeComponent,
        MenuComponent,
        MeusdadosComponent,
        NotaComponent,
        NotFoundComponent,
        RecebimentomensalidadeComponent,
        RedefinirsenhaComponent,
        Rematricula,
        TransacoesComponent,
        TurmaComponent,
        TurmadetalheComponent,
        UploadBannerDialog,
        UploadFotoDialog,
        UploadFotoFuncionarioDialog,
        UsuarioComponent,
        EditarboletoComponent,
        EmailinternoComponent,
        AbriranoComponent,
        FichaindividualComponent,
        AlteraranoComponent,
        ToolbarComponent,
        AtaconselhoclasseComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ComponentsModule,
        CurrencyMaskModule,
        DragDropModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        LayoutModule,
        MaterialModule,
        NgxDocViewerModule,
        NgxHmCarouselModule,
        NgxMaskModule.forRoot(),
        NgxPaginationModule,
        ReactiveFormsModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({stateKey:"router"}),
        ImageCropperModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: "pt" },
        { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
        AdministracaoService, AlunoService, AlunoPresencaService, BannerService, CepService, DiarioService, EmailService,
        FinanceiroService, FuncionarioService, LoggedInGuard, LoginService, MensagemService,
        NotaService, NumeroService, TurmaService, UtilService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }