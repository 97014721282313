import { Component, OnDestroy, OnInit } from '@angular/core';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { NotaService } from '@shared/service/nota.service';
import { Turma } from 'src/app/shared/model/turma.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { Aluno } from 'src/app/shared/model/aluno.model';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { map } from 'jquery';
import { DiarioService } from '@app/shared/service/diario.service';
import { AlunoPresencaService } from '@app/shared/service/alunopresenca.service';

@Component({
  selector: 'app-diarioclasse',
  templateUrl: './diarioclasse.component.html',
  styleUrls: ['./diarioclasse.component.css']
})
export class DiarioclasseComponent implements OnInit, OnDestroy {
avaliacao:string[]= ['nome','id']

  columns: any[] = [{ columnDef: 'nome', header: 'Disciplina', cell: 'nomeDisciplina', size: '30', edicao: '0'}]
  displayedColumns: any
  dataSource: any

  idTurma: number
  nomeTurma: string

  alunosNotas : any[] = []
  alunos: Aluno[]
  turmas: Turma[]
  
  ano: number
  nomeEscola:string
  id:number
  bimestre:string = "1"

  doc: string // = "http://backend.sapienseducacional.com.br/uploads/1/docs/raw.docx"

  liberacao: boolean = false

  avaliacaoGrid: string[] = [] 
  
  disciplinas: any[]
  idDisciplina: number
  nomeDisciplina: string

  subscription: Subscription = new Subscription();
	onDestroyer$ = new Subject()

  constructor(private turmaService: TurmaService,
              private alunoService: AlunoService,
              private alunoPresencaService: AlunoPresencaService,
              private diarioService: DiarioService,
              private loginService: LoginService,
              private notaService: NotaService) {
                 
                this.notaService.obterAvaliacoesBoletim(this.loginService.retornarAno())
                .pipe(takeUntil(this.onDestroyer$))
                .subscribe(result=>{
                  let tamanho = (70/ result.length)
                  result.forEach(element => {
                    let liberacao = 'N'
                    if(element.dataLiberacao!=null){
                      if(moment().format()>= moment(element.dataLiberacao).format()){
                        liberacao = 'S'
                      }
                    }
                    this.columns.push({ columnDef: element.id.toString(), header: element.nomeReduzido== undefined? element.nome : element.nomeReduzido, cell:element.id.toString(), size: tamanho, edicao: liberacao=='N' ?'0':'1' })
                  });
                  this.displayedColumns = this.columns.map(c => c.columnDef);
                })
              }

  ngOnInit() {
    
    this.loginService.obterDadosEscola().pipe(takeUntil(this.onDestroyer$)).subscribe(result=>{
      this.id = result.id
      this.nomeEscola = result.descricao
    })
    this.ano = this.loginService.retornarAno()

    this.turmaService.obterTurmaCombo(this.ano).pipe(takeUntil(this.onDestroyer$)).subscribe(result => {
      this.turmas = result
    })
  }
  
  ngOnDestroy(): void {
    this.onDestroyer$.unsubscribe()
  }

  async impressao(){
    this.liberacao = false
    this.doc = ""

    swal.fire({
      title: 'Gerando arquivos',
      allowOutsideClick: false
    });
    swal.showLoading();

    this.alunosNotas.sort((a, b) => a.position - b.position)
    this.diarioService.obterDiarioDisciplinaPeriodo(this.idTurma, this.idDisciplina).pipe(takeUntil(this.onDestroyer$)).subscribe({
      next: resultDiario=>{
        this.alunoPresencaService.obterPresencaPorAlunoPeriodoDisciplina(this.idTurma, this.idDisciplina).pipe(takeUntil(this.onDestroyer$)).subscribe({
          next: resultAlunos=>{
            this.notaService.gerarDiarioDeClasse({ dados: {diario:resultDiario, alunos: resultAlunos}, bimestre: this.bimestre, disciplina: this.nomeDisciplina }).pipe(takeUntil(this.onDestroyer$)).subscribe({
              next: ()=>{
                setTimeout( () => {
                  console.log("Diario " + resultDiario[0].nome.replace("º","") + 
                             " - " + this.nomeDisciplina + " - " + 
                             this.bimestre + " Bimestre - " + moment(resultDiario[0].dia).format('YYYY') + ".docx");
                  
                  this.doc = "http://backend.sapienseducacional.com.br/uploads/1/docs/" + "Diario " + resultDiario[0].nome.replace("º","") + 
                             " - " + this.nomeDisciplina + " - " + 
                             this.bimestre + " Bimestre - " + moment(resultDiario[0].dia).format('YYYY') + ".docx"

                  swal.close()    
                  this.liberacao = true  
                }, 3000 ); 
              }
            })
          }
        })
      }
    })

                      swal.close()    
                  this.liberacao = true 
  }

  async retornaAlunos(){
    this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano).pipe(takeUntil(this.onDestroyer$)).subscribe(async result =>{
      this.alunos = result
      this.retornaNotas()
    })
  }

  retornaNotas(){

    this.liberacao = false
    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunosNotas = []

    this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano).pipe(takeUntil(this.onDestroyer$)).subscribe(async result =>{
      
      await result.forEach(element => {

        this.notaService.obterNotasAluno(element.id, this.ano).pipe(takeUntil(this.onDestroyer$)).subscribe( retorno => {
          this.nomeTurma = retorno[0].nomeReduzido
          this.alunosNotas.push(retorno)          
        })

      });
      
      setTimeout( () => {
        swal.close()
        this.liberacao = true
      }, 2000 ); 

    })
  }

  setDisciplina(disciplina: number){
    this.idDisciplina = disciplina

    console.log(this.idTurma, this.idDisciplina);
    
    this.nomeDisciplina = this.disciplinas.find(x=>x.id == disciplina).nome;
  }

  setTurma(turma: number){
    this.idTurma = turma
    this.idDisciplina = undefined
    this.turmaService.obterDisciplinaTurma(this.idTurma, this.loginService.retornarAno())
      .pipe(takeUntil(this.onDestroyer$))
      .subscribe(result => {      
        this.disciplinas = result
    })
    
  }
}
