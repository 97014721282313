import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, Form } from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Turma } from 'src/app/shared/model/turma.model';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import swal from 'sweetalert2';
import { EmailService } from '@shared/service/email.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-emailmensagem',
  templateUrl: './emailmensagem.component.html',
  styleUrls: ['./emailmensagem.component.scss']
})
export class EmailmensagemComponent implements OnInit {

  isLinear = false
  
  emailForm: FormGroup
  alunosForm: FormGroup
  finalizarForm: FormGroup

  turma : Turma[]
  alunosSelecionados: any[] = []

  bloqueiaEnvio:boolean = false

  idTurma: number
  alunos: any[] = [];

  displayedColumnsAlunos = ['nome', 'email', 'acao']
  dataSourceAlunos: MatTableDataSource<any>;

  displayedColumnsSelecionados = ['nome', 'email', 'acao']
  dataSourceSelecionados: MatTableDataSource<any>;

  displayedColumnsFinalizar = ['nome']

  @ViewChild('stepper') stepper: MatStepper

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  constructor(private _formBuilder: FormBuilder,
              private loginService: LoginService,
              private emailService: EmailService,
              private turmaService: TurmaService,
              private alunoService: AlunoService) {}

  ngOnInit() {
    this.emailForm = this._formBuilder.group({
      titulo: ['', Validators.required],
      mensagem: ['', Validators.required]
    });

    this.alunosForm = this._formBuilder.group({
      idTurma: ['']
    });

    this.finalizarForm = this._formBuilder.group({
      titulo: [''],
      mensagem: ['']
    });

    this.turmaService.obterTurmaComboMensalidade(this.loginService.retornarAno()).subscribe(result => {
      this.turma = result
    })
  }

  finalizarEmail(titulo: string, mensagem: string){

    setTimeout( () => {
      if(this.emailForm.invalid){
        swal.fire({
          title: 'Sapiens',
          text: 'Informe o título e o conteúdo do e-mail.',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout( () => {
          this.stepper.selectedIndex = 0
        }, 70 );
      }else if(this.dataSourceSelecionados==undefined){
        swal.fire({
          title: 'Sapiens',
          text: 'Selecione o(s) aluno(s).',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout( () => {
          this.stepper.selectedIndex = 1
        }, 70 );
      }else{
        this.finalizarForm.patchValue({
          titulo: titulo,
          mensagem: mensagem
        })
      }
    }, 70 );
  }

  enviar(titulo:string, mensagem:string){

    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente enviar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {

      if (retorno.value) {
        swal.fire({
          title: 'Enviando...'
        });
        
        this.bloqueiaEnvio = true
        let emails = []
    
        this.alunosSelecionados.forEach(element => {
          emails.push(element.email)
        });
        let dados = [{'email':emails, 'subject': titulo,'text': mensagem}]
    
        this.emailService.enviarEmailGenerico(dados).subscribe(result=>{
          
          swal.close()
    
          if(result.res = "Ok"){
            swal.fire({
              title: 'Sapiens',
              text: 'E-mail(s) enviado(s) com sucesso.',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            })
            this.bloqueiaEnvio = false
          }
        })
      }
    })   
  }

  excluir(idAluno: number){
    let index:number = this.alunosSelecionados.findIndex(result=> result.id === idAluno)
    this.alunosSelecionados.splice(index,1)
    this.dataSourceSelecionados = new MatTableDataSource(this.alunosSelecionados)
    this.retornaAlunos(this.idTurma)
  }
 
  incluir(idAluno: number, nome: string, email: string){
    this.alunosSelecionados.push({"id":idAluno, "nome": nome, "email": email})
    this.dataSourceSelecionados = new MatTableDataSource(this.alunosSelecionados)
    this.retornaAlunos(this.idTurma)
  }

  retornaAlunos(id:number){

    this.idTurma = id
    this.alunoService.obterAlunosComboEmail(id, this.loginService.retornarAno()).subscribe(retorno => {
  
      this.alunos.splice(0,this.alunos.length)

      retorno.forEach(element => {

        let index = (this.alunosSelecionados.findIndex(result=> result.id === element.id))
        if(index===-1){
          this.alunos.push({"id": element.id, "nome": element.nome, "email": element.email})
        }
      });

      this.dataSourceAlunos = new MatTableDataSource(this.alunos);
    })

  }
}