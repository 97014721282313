import { Component, OnInit } from '@angular/core';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { NotaService } from '@shared/service/nota.service';
import { Turma } from 'src/app/shared/model/turma.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { Aluno } from 'src/app/shared/model/aluno.model';

@Component({
  selector: 'app-ataconselhodeclasse',
  templateUrl: './ataconselhodeclasse.component.html',
  styleUrls: ['./ataconselhodeclasse.component.css']
})
export class AtaconselhoclasseComponent implements OnInit {
  avaliacao:string[]= ['nome','id']

  columns: any[] = [{ columnDef: 'nome', header: 'Disciplina', cell: 'nomeDisciplina', size: '30', edicao: '0'}]
  displayedColumns: any
  dataSource: any

  idTurma: number
  nomeTurma: string

  alunosNotas : any[] = []
  alunos: Aluno[]
  turmas: Turma[]
  
  ano: number
  nomeEscola:string
  id:number
  bimestre:string = "1"

  doc: string // = "http://backend.sapienseducacional.com.br/uploads/1/docs/raw.docx"

  liberacao: boolean = false

  avaliacaoGrid: string[] = [] 
  
  constructor(private turmaService: TurmaService,
              private alunoService: AlunoService,
              private loginService: LoginService,
              private notaService: NotaService) {
                 
                this.notaService.obterAvaliacoesBoletim(this.loginService.retornarAno())
                .subscribe(result=>{
                  let tamanho = (70/ result.length)
                  result.forEach(element => {
                    let liberacao = 'N'
                    if(element.dataLiberacao!=null){
                      if(moment().format()>= moment(element.dataLiberacao).format()){
                        liberacao = 'S'
                      }
                    }
                    this.columns.push({ columnDef: element.id.toString(), header: element.nomeReduzido== undefined? element.nome : element.nomeReduzido, cell:element.id.toString(), size: tamanho, edicao: liberacao=='N' ?'0':'1' })
                  });
                  this.displayedColumns = this.columns.map(c => c.columnDef);
                })
              }

  ngOnInit() {
    
    this.loginService.obterDadosEscola().subscribe(result=>{
      this.id = result.id
      this.nomeEscola = result.descricao
    })
    this.ano = this.loginService.retornarAno()

    this.turmaService.obterTurmaCombo(this.ano).subscribe(result => {
      this.turmas = result
    })
  }

  async impressao(){
    this.liberacao = false
    this.doc = ""

    swal.fire({
      title: 'Gerando arquivos',
      allowOutsideClick: false
    });
    swal.showLoading();

    this.alunosNotas.sort((a, b) => a.position - b.position)

    this.notaService.gerarAta(this.alunosNotas, this.bimestre ).subscribe(result=> {
      setTimeout( () => {
        this.doc = "http://backend.sapienseducacional.com.br/uploads/1/docs/" + "Ata " + this.alunosNotas[0][0].nomeReduzido + " - " + this.bimestre + " Bimestre - "+ this.alunosNotas[0][0].ano + ".docx"
        swal.close()    
        this.liberacao = true  
      }, 3000 ); 
    })
  }

  async retornaAlunos(){
    this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano).subscribe(async result =>{
      this.alunos = result
      this.retornaNotas()
    })
  }

  retornaNotas(){

    this.liberacao = false
    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunosNotas = []
    this.alunoService.obterAlunoComboTurma(this.idTurma, this.ano).subscribe(async result =>{
      
      await result.forEach(element => {

        this.notaService.obterNotasAluno(element.id, this.ano).subscribe( retorno => {
          this.nomeTurma = retorno[0].nomeReduzido
          this.alunosNotas.push(retorno)          
        })

      });
      
      setTimeout( () => {
        swal.close()
        this.liberacao = true
      }, 2000 ); 

    })
  }
}
