import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { Diario } from "../model/diario.model";

@Injectable()

export class DiarioService{

    constructor(private http: HttpClient){}

    create(diario: Diario): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/diario`, diario)
        .pipe(map((result: any) => result))
    }

    obterDiarioDisciplina(idDisciplina: number): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/diario/disciplina/${idDisciplina}`)
    }

    obterDiarioDisciplinaAll(idTurma): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/diario/disciplinaturma/${idTurma}`)
    }

    obterDiarioDisciplinaDia(idDisciplina:number, dia:string): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/diario/disciplinadia/${idDisciplina}/${dia}`)
    }   

    obterDiarioDisciplinaPeriodo(idTurma:number, idDisciplina:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/diario/disciplinaperiodo/${idTurma}/${idDisciplina}`)
            .pipe(map((result: any) => result))
    }

    obterDiarioValidacao(idTurma:number, idDisciplina:number, dia:string): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/diario/validamarcacao/${idTurma}/${idDisciplina}/${dia}`)
    }
    update(diario: Diario): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/diario`, diario)
            .pipe(map((result: any) => result))
    }

}