// // Local
// export const BACKEND_API = "http://localhost:8081/api" 
// export const BACKEND_FILE = "http://localhost:8081/files"

// Homologação
//export const BACKEND_API = "https://bsapiens.leafdev.com.br/api" 
//export const BACKEND_FILE = "https://bsapiens.leafdev.com.br/files"

//Produção
export const BACKEND_API = "https://backend.sapienseducacional.com.br/api"
export const BACKEND_FILE = "https://backend.sapienseducacional.com.br/files"

export const crypt = "fdj%S21@dd/s/51DST_Ywdki1$$$3cSsdFDP123"

export const pusherKey = '682be2803506da773167'
export const pusherSecret = "b23f5837a19b23c7f4c0"
export const pusherChannel = "Sapiens-development"