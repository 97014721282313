import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";

@Injectable()

export class AdministracaoService{

    constructor(private http: HttpClient){}

    abrirAnoLetivo(ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/anoletivo/abrir/${ano}`)
    }

    create(acesso: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/acessos`, acesso)
        .pipe(map((result: any) => result))
    }

    obterAcessos(idTipoFuncionario:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/acessos/${idTipoFuncionario}`)
    }
    
    obterAcessoMenu(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/acessos/menu`)
    }

    obterAlunosComboEmail(idTurma:number, ano:number): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/alunos/comboemail/${idTurma}/${ano}`)
    }
    
    update(acesso: any): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/acessos`, acesso)
        .pipe(map((result: any) => result))
    }
}