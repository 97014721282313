import { Component, OnInit } from '@angular/core';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { Turma } from 'src/app/shared/model/turma.model';
import { LoginService } from 'src/app/shared/service/login.service';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import { FinanceiroService } from '@shared/service/financeiro.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { Aluno } from 'src/app/shared/model/aluno.model';

@Component({
  selector: 'app-geracaoboleto',
  templateUrl: './geracaoboleto.component.html',
  styleUrls: ['./geracaoboleto.component.scss']
})

export class GeracaoboletoComponent implements OnInit {

  idTurma: number
  idAluno: number

  alunos: Aluno[]
  turmas: Turma[]
  
  meses: any[] = []
  mesSelecionado : number

  alunosImpressao: any[]
  dataVencimento: any

  gerarBoleto:boolean 

  ano: number
  constructor(private turmaService: TurmaService,
              private alunoService: AlunoService,
              private financeiroService: FinanceiroService,
              private loginService: LoginService) {}

  ngOnInit() {
    moment.locale('pt-br')
    this.ano = this.loginService.retornarAno()

    for(let x = 1; x<13; x++){
      this.meses.push({'id':x, 'nome':moment(this.ano +"-"+ x +"-10").format('MMMM')})
    }

    this.mesSelecionado= Number(moment().format('M'))

    this.vencimento()

    this.turmaService.obterTurmaComboMensalidade(this.ano).subscribe(result => {
      this.turmas = result
    })
  }

  gerarBoletos(alunosSelecionados){

    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente gerar boleto(s)?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {
        let selecionados: any[] = []

        alunosSelecionados.forEach(element => {
          selecionados.push({'id':element.value,'vencimento':this.dataVencimento})
        });

        this.financeiroService.gerarBoleto(selecionados, this.mesSelecionado, this.ano).subscribe(result=>{
        })

        swal.fire({
          title: 'Sapiens',
          text: 'Boleto(s) gerado(s) com sucesso!',
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
  }

  async retornaAlunos(){
    this.alunoService.obterAlunoListaBoleto(this.idTurma, this.ano).subscribe(async result =>{
      this.alunos = result
      this.idAluno = 0
    })
  }

  vencimento(){
    this.financeiroService.obterDatasPagamento(this.ano).subscribe(result=>{
      result.forEach(element => {
        if(element.mes == this.mesSelecionado){
          this.dataVencimento = element.dataLimite
          if(moment(element.dataLimite).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')){
            this.gerarBoleto=false
          } else {
            this.gerarBoleto=true
          }
        }
      });
    })
  }
}