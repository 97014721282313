import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BACKEND_API } from '@app/app.api';
import { Router } from '@angular/router';
import { MensagemInterna } from '@shared/model/mensagem.model';

@Injectable()
export class MensagemService {

  lastUrl: string
    constructor(private http: HttpClient, private router: Router) {
  }
  
  enviarMensagem(dados: MensagemInterna): Observable<any> {
    return this.http.post<any>(`${BACKEND_API}/mensagens/enviar`, dados)
  }

  obterDestinatarioMensagem(id:number): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/mensagens/destinatarios/${id}`)
  } 

  obterMensagemId(id:number): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/mensagens/id/${id}`)
  } 
  
  obterMensagensEnviadas(): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/mensagens/enviadas`)
  } 

  obterMensagensNaoLidas(): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/mensagens/naolidas`)
  } 

  obterMensagensRecebidas(): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/mensagens/recebidas`)
  } 
  
}