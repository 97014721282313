import {Component, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

import {Router} from "@angular/router";
import { LoginService } from '@shared/service/login.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html'
})
export class EsqueciSenhaComponent implements OnInit {

  esqueciSenhaForm: FormGroup
  validacao: boolean = false

  constructor(private loginService: LoginService,
              private fb: FormBuilder,
              private router: Router) {

  }

  ngOnInit() {
    this.esqueciSenhaForm = this.fb.group({
      email: this.fb.control('', [Validators.required])
    })
  }

  solicitarNovaSenha(login: string) {
    this.loginService.requestNewPassword(login)
      .subscribe((res) => {
      if(res['message'] == "Ok") {
        swal.fire({
          title: 'Solicitação de Nova Senha',
          text: 'Um email será enviado em alguns instantes com orientações para a redefinição de sua senha. Caso demore, verifique na caixa de spans.',
          icon: 'success',
          showConfirmButton: false,
          timer: 6500
        })
        setTimeout( () => {
          this.router.navigate(['/login'])
        }, 6500 );
      } else {
        this.validacao = true
      }
    })
  }
}