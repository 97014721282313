import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TurmaService } from '@shared/service/turma.service';
import { Turma } from '@shared/model/turma.model';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-formturma',
  templateUrl: './formturma.component.html',
  styleUrls: ['./formturma.component.scss']
})
export class FormturmaComponent implements OnInit {
  
  turmaForm : FormGroup

  id : number = 0
  idValor : number = 0
  serie : any[]

  constructor(private turmaService: TurmaService,
              private loginService: LoginService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute) { 
                
                this.id = this.activatedRoute.snapshot.params['id']==undefined ? 0 : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {
    this.turmaForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      nomeReduzido: this.fb.control('', [Validators.required]),
      sala: this.fb.control(''),
      idSerie: this.fb.control('', [Validators.required]),
      turno: this.fb.control('', [Validators.required]),
      valor: this.fb.control(''),
      status: this.fb.control('')
    })

    this.turmaService.obterSerie().subscribe(result=>{
      this.serie = result
    })

    this.getTurma(this.id)

  }

  getTurma(id: number){

    if(id) {
    
      this.turmaService.obterTurmaId(id, this.loginService.retornarAno()).subscribe(result => {

        this.turmaForm.patchValue({
          nome : result[0].nome,
          nomeReduzido : result[0].nomeReduzido,
          sala : result[0].sala,
          idSerie : result[0].idSerie,
          turno : result[0].turno,
          valor : result[0].valor.toFixed(2),
          ano : this.loginService.retornarAno(),
          status : result[0].status=='A'?true:false
        })
        this.idValor = result[0].idValor
      })

    }
  }

  gravar(turma : Turma){

    turma.status = turma.status ?'A':'D'

    if(this.id==0){
      this.save(turma)
    }else{
      this.update(turma)
    }

  }

  save(turma : Turma){

    this.turmaService.create(turma).subscribe(result=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Turma cadastrada com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      this.turmaForm.reset()

    },(error)=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Ocorreu um erro no cadastro de turma.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(turma: Turma) {
    
    turma.id = this.id
    turma.idValor = this.idValor

    this.turmaService.update(turma).subscribe(result=>{

      swal.fire({
        title: 'Sapiens',
        text: 'Turma alterada com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/turmas'])
        }, 1000 );
      },(error: HttpErrorResponse)=>{
    })
  }

}