import { Component, OnInit, ViewChild } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { NotaService } from '@shared/service/nota.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { Avaliacao } from 'src/app/shared/model/nota.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.scss']
})

export class AvaliacaoComponent implements OnInit {

  displayedColumns = ['status', 'nome', 'nomeReduzido', 'ordem', 'acao']
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort) sort: MatSort;
  
  avaliacoes: Avaliacao[]
  ordemAlterada: boolean = false

  constructor(private notaService: NotaService,
              private loginService : LoginService,
              private router: Router) { }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.avaliacoes, event.previousIndex, event.currentIndex);
    this.ordemAlterada = true
  }
  ngOnInit() {

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.load()
  }

  load(){
    this.notaService.obterAvaliacoes(this.loginService.retornarAno()).subscribe(result =>{
      this.avaliacoes = result

      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      swal.close()

    })
  }

  async salvarOrdenacao(){

    let x = 1
    let avaliacoesOrdem = []
    await this.avaliacoes.forEach(element => {
      avaliacoesOrdem.push({'id': element.id, 'ordem':x})
      x++
    });

    this.notaService.updateAvalicaoOrdem(avaliacoesOrdem, this.loginService.retornarAno()).subscribe(result =>{
      this.load()
    })

  }

  update(id: number){
    this.router.navigate(['dashboard/notas/avaliacoes/form',id]);
  }

}