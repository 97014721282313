import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Filtro } from "./util.model";
import { Observable } from "rxjs";
import { BACKEND_API } from "src/app/app.api";

@Injectable()

export class UtilService{

    constructor(private http: HttpClient){ }
        
    calculoDia(data: Date, dias: number, diaUtil: Boolean){
        
    }

    dataAtual(): string {
        var data = new Date();
        var dia = data.getDate().toString();
        var mes = (data.getMonth() + 1).toString();
        var ano = data.getFullYear();
        if (mes.toString().length < 2) mes = '0' + mes;
        if (dia.toString().length < 2) dia = '0' + dia;
        return (ano + "-" + mes + "-" + dia)
    }

    primeiroDia(mesReferencia: number, anoReferencia: number): string {
        var dia = '01';
        var mes = mesReferencia.toString();
        var ano = anoReferencia.toString();
        if (mes.toString().length < 2) mes = '0' + mes;
        return (ano + "-" + mes + "-" + dia)
    }    
    
    retornaCidades(uf: string): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/util/municipios/${uf}`)
    }

    retornaFiltro(filtro: string) : Filtro{

        let tmpFiltro: Filtro = new Filtro

        if(sessionStorage.getItem("_SapiensFiltros")!= null){
            JSON.parse(sessionStorage.getItem("_SapiensFiltros")).forEach(element => {
                if(element.nome==filtro){
                    tmpFiltro.nome = element.nome
                    tmpFiltro.filtro = element.filtro
                    tmpFiltro.pagina = element.pagina
                    tmpFiltro.qtdPagina = element.qtdPagina
                }
            });
        }

        return tmpFiltro
    }

    setaFiltro(filtro: Filtro){
        
        let filtroTmp = []

        filtroTmp.push(filtro)
        sessionStorage.setItem("_SapiensFiltros", JSON.stringify(filtroTmp))
        
    }
    
    validarCPF(cpf: string) : boolean{

        if (cpf) {
            let numbers, digits, sum, i, result, equalDigits;
            equalDigits = 1;
            if (cpf.length < 11) {
            return null;
            }

            for (i = 0; i < cpf.length - 1; i++) {
            if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
                equalDigits = 0;
                break;
            }
            }

            if (!equalDigits) {
            numbers = cpf.substring(0, 9);
            digits = cpf.substring(9);
            sum = 0;
            for (i = 10; i > 1; i--) {
                sum += numbers.charAt(10 - i) * i;
            }

            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            if (result !== Number(digits.charAt(0))) {
                return false;
            }
            numbers = cpf.substring(0, 10);
            sum = 0;

            for (i = 11; i > 1; i--) {
                sum += numbers.charAt(11 - i) * i;
            }
            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            if (result !== Number(digits.charAt(1))) {
                return false;
            }

            return true;

            } else {
            return false;
            }
        }
    }

    ultimoDia(mesReferencia: number, anoReferencia: number): string {
        var ultimoDia = new Date(anoReferencia, mesReferencia, 0);
        var dia = ultimoDia.getDate();
        var mes = (ultimoDia.getMonth() + 1).toString();
        var ano = ultimoDia.getFullYear();
        if (mes.toString().length < 2) mes = '0' + mes;
        return (ano + "-" + mes + "-" + dia)
    }   

}