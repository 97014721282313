import { Component, OnInit, ViewChild } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { Turma } from 'src/app/shared/model/turma.model';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { ListaAlunosTurmaMensalidade } from '../financeiro.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UtilService } from 'src/app/shared/util/util.service';
import { LoginService } from 'src/app/shared/service/login.service';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-mensalidade',
  templateUrl: './mensalidade.component.html',
  styleUrls: ['./mensalidade.component.scss']
})
export class MensalidadeComponent implements OnInit {
    
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  mensalidadeForm : FormGroup
  turma : Turma[]
  idTurma : number

  dataAtual: any
  vencimentoJane: boolean = false
  vencimentoFeve: boolean = false
  vencimentoMarc: boolean = false
  vencimentoAbri: boolean = false
  vencimentoMaio: boolean = false
  vencimentoJunh: boolean = false
  vencimentoJulh: boolean = false
  vencimentoAgos: boolean = false
  vencimentoSete: boolean = false
  vencimentoOutu: boolean = false
  vencimentoNove: boolean = false
  vencimentoDeze: boolean = false

  constructor(private turmaService: TurmaService,
              private loginService : LoginService,
              private financeiroService: FinanceiroService,
              private utilService: UtilService,
              private router: Router) { }

  displayedColumns = ['nome', 'jane', 'feve', 'marc', 'abri', 'maio', 'junh', 'julh', 'agos', 'sete', 'outu', 'nove', 'deze']
  dataSource: MatTableDataSource<ListaAlunosTurmaMensalidade>;

  ngOnInit() {

    this.dataAtual = this.utilService.dataAtual()

    this.turmaService.obterTurmaComboMensalidade(this.loginService.retornarAno()).subscribe(result => {
      this.turma = result
    })

    this.financeiroService.obterDatasPagamento(this.loginService.retornarAno()).subscribe(result => {

      if(result[0].dataLimite < this.dataAtual)
        this.vencimentoJane = true
      
      if(result[1].dataLimite < this.dataAtual)
        this.vencimentoFeve = true
      
      if(result[2].dataLimite < this.dataAtual)
        this.vencimentoMarc = true
      
      if(result[3].dataLimite < this.dataAtual)
        this.vencimentoAbri = true
      
      if(result[4].dataLimite < this.dataAtual)
        this.vencimentoMaio = true
      
      if(result[5].dataLimite < this.dataAtual)
        this.vencimentoJunh = true

      if(result[6].dataLimite < this.dataAtual)
        this.vencimentoJulh = true
      
      if(result[7].dataLimite < this.dataAtual)
        this.vencimentoAgos = true
      
      if(result[8].dataLimite < this.dataAtual)
        this.vencimentoSete = true
      
      if(result[9].dataLimite < this.dataAtual)
        this.vencimentoOutu = true
      
      if(result[10].dataLimite < this.dataAtual)
        this.vencimentoNove = true
      
      if(result[11].dataLimite < this.dataAtual)
        this.vencimentoDeze = true

    })

  }

  recebimento(matricula : string){
    this.router.navigate([`dashboard/financeiro/recebimentomensalidade/${matricula}`])
  }

  retornaAlunos(){

    this.financeiroService.obterMensalidadeListaAlunoTurma(this.idTurma, this.loginService.retornarAno()).subscribe(result => {
      
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

  }

}