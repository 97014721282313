import { Component, OnInit, ViewChild } from '@angular/core';
import { TurmaService } from '@shared/service/turma.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { FuncionarioService } from 'src/app/shared/service/funcionario.service';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { Disciplina } from '@shared/model/turma.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-formdisciplina',
  templateUrl: './formdisciplina.component.html',
  styleUrls: ['./formdisciplina.component.scss']
})
export class FormdisciplinaComponent implements OnInit {

  id : number
  disciplinaForm : FormGroup
  disciplina : Disciplina[]
  idTurma: number
  idSerie: number
  nomeTurma: string
  turno: string
  professores: any[] = []

  statusDisciplina = 'V'

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['disciplina', 'nomeProfessor', 'nota', 'acao']
  dataSource: MatTableDataSource<any>;
  
  constructor(private turmaService: TurmaService,
              private funcionarioService: FuncionarioService,
              private loginService: LoginService,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute) { 
    this.idTurma = this.activatedRoute.snapshot.params.idTurma==undefined ? 0 : this.activatedRoute.snapshot.params.idTurma
  }

  ngOnInit() {

    this.disciplinaForm = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      nota: this.fb.control(''),
      idProfessor: this.fb.control('')
    })

    this.turmaService.obterTurmaId(this.idTurma, this.loginService.retornarAno()).subscribe(result=>{
      this.idSerie = result[0].idSerie
    })

    this.load()

    this.funcionarioService.obterProfessoresCombo().subscribe(result=>
      {
        this.professores = result
      })
      this.professores.push({id: null, nome: "Professor não cadastrado"})
  }

  load(){
    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.turmaService.obterTurmaDisciplinaProfessor(this.idTurma, this.loginService.retornarAno())
    .subscribe(retorno=>{

      this.nomeTurma = retorno[0].nome
      this.turno = retorno[0].turno == "M" ? "Manhã" : retorno[0].turno == "T" ? "Tarde" : "Integral"

      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    })
  }

  cancelar(){
    this.disciplinaForm.reset()
    this.statusDisciplina = 'V'
    this.id  = undefined
  }

  fill(idDisciplina : number, nome : string, idProfessor : number, nota : string){
    this.disciplinaForm.patchValue({
      nome : nome,
      idProfessor : idProfessor,
      nota : nota == "S"? true : false
    })
    this.id = idDisciplina
    this.statusDisciplina = 'E'
  }

  gravar(disciplinaForm: Disciplina){

    disciplinaForm.nota = disciplinaForm.nota.toString() == 'true' ? 'S' : 'N'
    if(this.id==undefined){
      this.create(disciplinaForm)
    }else{
      this.update(disciplinaForm)
    }

  }

  novaDisciplina(){
    this.statusDisciplina = 'N'
  }

  create(disciplinaForm: Disciplina){

    disciplinaForm.ano = this.loginService.retornarAno()
    disciplinaForm.idTurma = this.idTurma
    disciplinaForm.idSerie = this.idSerie

    this.turmaService.createDisciplina(disciplinaForm).subscribe(result=>{

      swal.fire({
        title: 'Sapiens',
        text: 'Disciplina cadastrada com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      },(error: HttpErrorResponse)=>{
    })

    this.id = undefined
    this.disciplinaForm.reset
    this.statusDisciplina = 'V'
    
    setTimeout( () => {
      this.load()
    }, 2000 );

  }

  update(disciplinaForm: Disciplina) {

    disciplinaForm.id = this.id
    disciplinaForm.idTurma = this.idTurma
    disciplinaForm.idSerie = this.idSerie
    
    this.turmaService.updateDisciplina(disciplinaForm).subscribe(result=>{

      swal.fire({
        title: 'Sapiens',
        text: 'Disciplina alterada com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      },(error: HttpErrorResponse)=>{
    })
      
    this.id = undefined
    this.disciplinaForm.reset
    this.statusDisciplina = 'V'
    
    setTimeout( () => {
      this.load()
    }, 2000 );
  }

}
