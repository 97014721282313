import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { Avaliacao } from "../model/nota.model";


@Injectable()

export class NotaService{

    constructor(private http: HttpClient){}

    createNota(nota: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/notas/create`, nota)
        .pipe(map((result: any) => result))
    }

    createAvaliacao(avaliacao: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/avaliacoes`, avaliacao)
        .pipe(map((result: any) => result))
    }

    deleteAvaliacao(id: number): Observable<any> {
        return this.http.post<any>(`${BACKEND_API}/avaliacoes/delete`, {"id":id})
        .pipe(map((result: any) => result))
    }  

    obterAvaliacaoId(id: number): Observable<Avaliacao> {
        return this.http.get<Avaliacao>(`${BACKEND_API}/avaliacoes/id/${id}`)
    }  

    obterAvaliacoes(ano: number): Observable<Avaliacao[]> {
        return this.http.get<Avaliacao[]>(`${BACKEND_API}/avaliacoes/${ano}`)
    }  

    obterAvaliacoesBoletim(ano: number): Observable<Avaliacao[]> {
        return this.http.get<Avaliacao[]>(`${BACKEND_API}/avaliacoes/boletim/${ano}`)
    }  

    gerarAta(dados: any[], bimestre: string): Observable<any> {
        return this.http.post<any>(`${BACKEND_API}/documento/ata/${bimestre}`, dados)
    }  

    gerarDiarioDeClasse(dados: any): Observable<any> {
        return this.http.post<any>(`${BACKEND_API}/documento/diariodeclasse`, dados)
    } 
    
    gerarFichaIndividual(dados: any[]): Observable<any> {
        return this.http.post<any>(`${BACKEND_API}/documento/fichaindividual`, dados)
    }  

    obterNotasAluno(idAluno: number, ano: number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/notas/aluno/${idAluno}/${ano}`)
    } 

    obterNotasTurma(idTurma: number, idDisciplina: number, ano: number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/notas/turma/${idTurma}/${idDisciplina}/${ano}`)
    }   

    avaliacaoPodeExcluir(id: number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/avaliacoes/podeexcluir/${id}`)
    }  
    
    updateAvalicao(avaliacao: any): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/avaliacoes`, avaliacao)
        .pipe(map((result: any) => result))
    }

    updateAvalicaoOrdem(ordem: any, ano: number): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/avaliacoes/updateordem/${ano}`, ordem)
        .pipe(map((result: any) => result))
    }

}