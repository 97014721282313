import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";

@Injectable()

export class AlunoPresencaService{

    constructor(private http: HttpClient){}

    obterPresencaPorAluno(idAluno:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunopresenca/alnuo/${idAluno}/${ano}`)
    } 

    obterPresencaPorAlunoPeriodoDisciplina(idTurma:number, idDisciplina:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunopresenca/alunoperiododisciplina/${idTurma}/${idDisciplina}`)
    }

    obterPresencaPorTurma(idturma:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunopresenca/turma/${idturma}/${ano}`)
    }   

    obterPresencaPorTurmaDisciplinaDia(idTurma:number, idDisciplina: number, dia:string): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/alunopresenca/turmadisciplinadia/${idTurma}/${idDisciplina}/${dia}`)
    }

    save(presenca: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/alunopresenca`, presenca)
        .pipe(map((result: any) => result))
    }

}