import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { FuncionarioGrid, Funcionario } from "../model/funcionario.model";

@Injectable()

export class FuncionarioService{

    constructor(private http: HttpClient){}
    
    create(funcionario: Funcionario): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/funcionarios`, funcionario)
        .pipe(map((result: any) => result))
    }
    
    obterFuncionarioFoto(id:number): Observable<any[]> {
        return this.http.post<any[]>(`${BACKEND_API}/files/funcionariofoto`,{"id":id})
    }

    obterFuncionarioId(id : number): Observable<Funcionario> {
        return this.http.get<Funcionario>(`${BACKEND_API}/funcionarios/obterFuncionarioId/${id}`)
    }  

    obterFuncionarioListaGrid(): Observable<FuncionarioGrid[]> {
        return this.http.get<FuncionarioGrid[]>(`${BACKEND_API}/funcionarios/obterfuncionariolistagrid`)
    }  
    
    obterProfessoresCombo(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/professores/combo`)
    } 

    obterTipoFuncionarioLista(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/tiposfuncionarios`)
    }  

    obterTipoFuncionarioListaUtilizada(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/tiposfuncionarios/utilizadas`)
    }  

    obterDisciplinaPorProfessor(professor:any, ano:number, turma: number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/funcionarios/obterdisciplinaporprofessor/${professor}/${ano}/${turma}`)
    } 

    obterTurmaPorProfessor(professor:any, ano:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/funcionarios/obterturmaporprofessor/${professor}/${ano}`)
    } 
    
    update(funcionario: Funcionario): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/funcionarios`, funcionario)
        .pipe(map((result: any) => result))
    }

    uploadFuncionarioFoto(file: any, id:number): Observable<any>{

        let form = new FormData()

            form.append('file', file)
            form.append('id', id.toString())

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }
        return this.http.post<any>(`${BACKEND_API}/files/uploadfuncionariofoto`, form)
        .pipe(map((result: any) => result))
    }
}