import { Component, OnInit, ViewChild, Inject, Output } from '@angular/core';
import { MensagemService } from '@shared/service/mensagem.service';
import { Router } from '@angular/router';
import { FuncionarioService } from '@shared/service/funcionario.service';
import swal from 'sweetalert2';
import { MensagemInterna } from '@shared/model/mensagem.model';
import {EventEmitter} from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface LerEmail {
  id: number;  
}

@Component({
  selector: 'app-emailinterno',
  templateUrl: './emailinterno.component.html',
  styleUrls: ['./emailinterno.component.scss']
})
export class EmailinternoComponent implements OnInit {

  @Output() public alteraQuantidade = new EventEmitter<number>();

  displayedColumnsRecebido = ['status', 'de', 'descricao', 'em', 'acao']
  dataSourceRecebido: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorRecebido: MatPaginator;
  @ViewChild(MatSort) sortRecebido: MatSort;

  displayedColumnsEnviado = ['status', 'para', 'descricao', 'em', 'acao']
  dataSourceEnviado: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorEnviado: MatPaginator;
  @ViewChild(MatSort) sortEnviado: MatSort;

  constructor(private mensagemService : MensagemService,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit() {

    this.loadRecebido()
    this.loadEnviado()
    this.alteraQuantidade.emit(0)
  }
  
  delete(id){
    console.log(id, this.alteraQuantidade.emit(id))
  }

  loadEnviado(){
    this.mensagemService.obterMensagensEnviadas().subscribe(retornoEnviadas => {

      retornoEnviadas.forEach(element => {
        this.mensagemService.obterDestinatarioMensagem(element.idMensagem).subscribe(result=>{
          result.forEach(elementDestinatarios => {

          });
        })
      });
      this.dataSourceEnviado = new MatTableDataSource(retornoEnviadas);
      this.dataSourceEnviado.paginator = this.paginatorEnviado;
      this.dataSourceEnviado.sort = this.sortEnviado;
    
    })
  }

  loadRecebido(){
    this.mensagemService.obterMensagensRecebidas().subscribe(retornoRecebidas => {

      this.dataSourceRecebido = new MatTableDataSource(retornoRecebidas);
      this.dataSourceRecebido.paginator = this.paginatorRecebido;
      this.dataSourceRecebido.sort = this.sortRecebido;
    
    })
  }

  openDialogEnviar(): void {
    const dialogRef = this.dialog.open(EnviarEmailInterno, {
      width: '800px',
      height: '600px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout( () => {
        this.loadEnviado()
      }, 1000 );
    });
  }
  
  openDialogLer(id:number): void {
    const dialogRef = this.dialog.open(LerEmailInterno, {
      width: '800px',
      height: '568px',
      disableClose: true,
      data:{id:id}
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout( () => {
        this.alteraQuantidade.emit(0)
        this.loadRecebido()
      }, 1000 );
    });
  }

}

@Component({
  selector: 'enviaremailinterno',
  templateUrl: './enviaremailinterno.html', 
  styleUrls: ['./emailinterno.component.scss']
})

export class EnviarEmailInterno {
  
  funcionarios: any[]
  para: any[]
  titulo: any = undefined
  corpo: any = undefined
  
  constructor(public dialogRef: MatDialogRef<EnviarEmailInterno>,
              private mensagemService : MensagemService,
              private funcionarioService: FuncionarioService) {
                this.funcionarioService.obterFuncionarioListaGrid().subscribe(result=>{
                  this.funcionarios = result
                })
              }

  async enviar(lista: any[]){
    if(lista.length == 0){
      swal.fire({
        title: 'Sapiens',
        text: 'Selecione pelo menos um destinatário!',
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      swal.fire({
        title: 'Sapiens',
        text: 'Deseja realmente enviar esse email?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      })
      .then(retorno => {
        if (retorno.value) {

          let dados: any[] = []
          let listaTmp: any[] = []

          lista.forEach(element => {
            listaTmp.push({"funcionarioId" : element.value})
          });
          
          dados.push({"descricao": this.titulo, "corpo": this.corpo, "para": listaTmp})

          this.mensagemService.enviarMensagem(new MensagemInterna(listaTmp, this.titulo, this.corpo)).subscribe(result=>{

          })

          swal.fire({
            title: 'Sapiens',
            text: 'Email enviado com sucesso!',
            icon: 'info',
            showConfirmButton: false,
            timer: 3000
          })
          this.dialogRef.close();
        }
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'leremailinterno',
  templateUrl: './leremailinterno.html', 
})

export class LerEmailInterno {

  de: any
  titulo: any
  corpo: any

  constructor(public dialogRef: MatDialogRef<LerEmailInterno>,
              private funcionarioService: FuncionarioService,
              private mensagemService : MensagemService,
              @Inject(MAT_DIALOG_DATA) public data: LerEmail) {
                
                this.mensagemService.obterMensagemId(data.id).subscribe(result=>{
                  this.de = result[0].nome
                  this.titulo = result[0].descricao
                  this.corpo = result[0].corpo
                })
              }

  gravar(){

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}