import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { SimpleCrypt } from "ngx-simple-crypt";
import { BACKEND_API, crypt } from '@app/app.api'
import { NavigationEnd, Router } from "@angular/router";
import { Usuario } from '../../pages/security/login/usuario.model';

@Injectable()
export class LoginService {

  anoEmitter = new EventEmitter<string>()

  lastUrl: string
  simpleCrypt = new SimpleCrypt();
  
  quantidadeNaoLida: any = 7

  qtdNaoLida():Observable<any>{
    return this.quantidadeNaoLida
  }

  obterDadosEscola(): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/escola`)
  } 

  obterAnos(): Observable<any[]> {
    return this.http.get<any[]>(`${BACKEND_API}/turmas/obteranos`)
  } 
  
  retornarAno(): number { 
    let dadosMain = JSON.parse(sessionStorage.getItem("main"))
    return Number(this.simpleCrypt.decode(crypt, dadosMain.ano))
  }

  setaAno(ano : string){
    sessionStorage.setItem("main",JSON.stringify({"ano": this.simpleCrypt.encode(crypt, ano.toString())}))
    this.anoEmitter.emit(ano)
  }

  constructor(private http: HttpClient, private router: Router) {
    this.router.events
        .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this.lastUrl = e.url)
  }

  //Identificar se o usuario esta logado
  isLoggedIn(): boolean {    
    let usuario: Usuario = localStorage.getItem("sapiens")?JSON.parse(localStorage.getItem("sapiens")):undefined
    return usuario !== undefined
  }

  usuario(): any {
    let storage = localStorage.getItem("sapiens")?JSON.parse(localStorage.getItem("sapiens")):''
    let dados = {"nome": this.simpleCrypt.decode(crypt,storage._nome), 
                 "id": this.simpleCrypt.decode(crypt,storage.id),             
                 "idEscola": this.simpleCrypt.decode(crypt,storage._idE), 
                 "accessToken":storage.accessToken,
                 "tipo": this.simpleCrypt.decode(crypt,storage._tipo)}
    return dados
  }

  login(usuario: Usuario): Observable<any> {
    return this.http.post<any>(`${BACKEND_API}O/auth/login`, usuario)
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['/login', btoa(path)])
  }

  logout() {
    this.router.navigate(['/login'])
    localStorage.removeItem("sapiens")
  }

  returnId(){
    return this.http.get<any>(`${BACKEND_API}/returnid`)
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get(`${BACKEND_API}/sendmail/recuperarsenha/${email}`)
  }

  requestNewPassword(email: string){
    return this.http.post(`${BACKEND_API}O/sendmail/recuperarsenha`,{'email':email})
  }
}