import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CepService } from 'src/app/shared/cep/cep.service';
import { FuncionarioService } from '@shared/service/funcionario.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BACKEND_FILE, crypt } from 'src/app/app.api';
import { SimpleCrypt } from 'ngx-simple-crypt';
import swal from 'sweetalert2';
import { Funcionario, TipoFuncionario } from 'src/app/shared/model/funcionario.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/shared/service/login.service';

export interface DialogDataFuncionario {
  id: number;
}

@Component({
  selector: 'app-formfuncionario',
  templateUrl: './formfuncionario.component.html',
  styleUrls: ['./formfuncionario.component.scss']
})
export class FormfuncionarioComponent implements OnInit {

  @ViewChild('nome') inputNome:ElementRef;
  funcionarioForm : FormGroup

  funcionario : Funcionario
  tipoFuncionario :  TipoFuncionario[]

  id : number = undefined
  foto: string
  idEscola: number
  simpleCrypt = new SimpleCrypt();
  
  logradouro : string = null
  bairro : string = null
  cidade : string = null
  uf : string = null

  constructor(private funcionarioService: FuncionarioService,
              private loginService: LoginService,
              private cepService: CepService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? 0 : this.activatedRoute.snapshot.params['id']

                let storage = localStorage.getItem("sapiens")?JSON.parse(localStorage.getItem("sapiens")):''
                this.idEscola = Number(this.simpleCrypt.decode(crypt,storage._idE))
              }

  ngOnInit() {

    this.funcionarioForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      userName: this.fb.control(''),
      telefone: this.fb.control(''),
      celular: this.fb.control(''),
      email: this.fb.control(''),
      cpf: this.fb.control('',[Validators.required]),
      rg: this.fb.control('',[Validators.required]),
      nascimento: this.fb.control(''),
      sexo: this.fb.control('',[Validators.required]),
      naturalidade: this.fb.control('',[Validators.required]),
      nacionalidade: this.fb.control('',[Validators.required]),
      estadoCivil: this.fb.control(''),
      cep: this.fb.control(''),
      complemento: this.fb.control(''),
      idTipoFuncionario : this.fb.control(''),
      observacao: this.fb.control(''),
      statusAcesso: this.fb.control(''),
      status: this.fb.control('')
    })

    if(this.id!=undefined){
      this.getFuncionario(this.id)
    } else {
      this.funcionarioForm.patchValue({
        status : true
      })
    }
    
    this.funcionarioService.obterTipoFuncionarioLista().subscribe(retorno =>{
      this.tipoFuncionario = retorno
    })

    this.retornarFoto()
  }
    
  buscaCEP(cepBusca:string){
    this.cepService.buscaCEP(cepBusca).subscribe(result => {
      if(result.cep!= undefined){
        this.logradouro = result.logradouro
        this.bairro = result.bairro
        this.cidade = result.localidade
        this.uf = result.uf
      } else {
        swal.fire({
          title: 'Sapiens',
          text: 'Cep incorreto!',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
        this.funcionarioForm.patchValue({
          cep: ""
        })
      }
    })
  }

  getFuncionario(id: number){

    if(id) {
    
      this.funcionarioService.obterFuncionarioId(id).subscribe(result => {

        this.funcionario = result

        this.logradouro = result[0].logradouro
        this.bairro = result[0].bairro
        this.cidade = result[0].cidade
        this.uf = result[0].uf

        this.funcionarioForm.patchValue({
          nome : result[0].nome,
          userName : result[0].userName,
          telefone : result[0].telefone,
          celular : result[0].celular,
          email : result[0].email,
          cpf : result[0].cpf,
          rg : result[0].rg,
          nascimento : result[0].nascimento,
          sexo : result[0].sexo, 
          naturalidade : result[0].naturalidade,
          nacionalidade : result[0].nacionalidade, 
          estadoCivil : result[0].estadoCivil,
          cep : result[0].cep,
          logradouro : result[0].logradouro,
          complemento : result[0].complemento,
          bairro : result[0].bairro,
          cidade : result[0].cidade,
          uf : result[0].uf,
          idTipoFuncionario : result[0].idTipoFuncionario,
          observacao : result[0].observacao,
          password : result[0].password,
          statusAcesso : result[0].password ? true : false,
          status : result[0].status == "A"? true : false
        })
      })
    }
  }

  gravar(funcionario : Funcionario){
    
    funcionario.status = funcionario.status.toString() == 'true' ? 'A' : 'D'

    if(this.id==0){
      this.save(funcionario)
    }else{
      this.update(funcionario)
    }
  }

  listaCEP(cep: string){
    if(cep!=null){
      if(cep.length==8){
        this.buscaCEP(cep)
      }else{
        this.logradouro = null
        this.bairro = null
        this.cidade = null
        this.uf = null
      }
    }
  }
    
  openDialog(): void {
    const dialogRef = this.dialog.open(UploadFotoFuncionarioDialog, {
      width: '400px',
      height: '600px',
      data:{ id : this.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.retornarFoto()
      }, 3000 );
    });
  }

  reenvioSenha(){
    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente reenviar email com senha? Essa ação mudará a senha do funcionário.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(enviar => {
      if (enviar.value) {

        this.loginService.resetPassword(this.funcionario[0].email).subscribe(res=>{
          this.funcionario[0].password = res.password
            swal.fire({
              title: 'Sapiens',
              text: 'Nova senha: ' + res.message ,
              icon: 'info',
              showConfirmButton: true
            })
        })
      }
    });
  }

  retornarFoto(){
    this.funcionarioService.obterFuncionarioFoto(this.id).subscribe(result=>{

      this.foto = result==null?null:result[0]
      this.foto = result==null?null: BACKEND_FILE + "/" + this.idEscola + "/foto/funcionarios/" + this.id +"/"+ result[0]
    })
  }

  save(funcionario : Funcionario){

    funcionario.logradouro = this.logradouro
    funcionario.bairro = this.bairro
    funcionario.cidade = this.cidade
    funcionario.uf = this.uf
    
    this.funcionarioService.create(funcionario).subscribe(result=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Funcionário cadastrado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      this.funcionarioForm.reset()
      this.logradouro = null
      this.bairro = null
      this.cidade = null
      this.uf = null

    },(error)=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Ocorreu um erro no cadastro de funcionário.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(funcionario: Funcionario) {

    this.funcionarioForm.value.statusAcesso ? funcionario.password = this.funcionario[0].password : funcionario.password = null

    funcionario.id = this.id
    funcionario.logradouro = this.logradouro
    funcionario.bairro = this.bairro
    funcionario.cidade = this.cidade
    funcionario.uf = this.uf

    this.funcionarioService.update(funcionario).subscribe(result=>{

      swal.fire({
        title: 'Sapiens',
        text: 'Funcionário alterado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/funcionarios'])
        }, 1000 );
      },(error: HttpErrorResponse)=>{
    })
  }
}

@Component({
  selector: 'uploadfoto',
  templateUrl: './uploadfoto.html',
})

export class UploadFotoFuncionarioDialog {
  imageChangedEvent: any = '';
  croppedImage: any = ''

  constructor(private funcionarioService: FuncionarioService,
              public dialogRef: MatDialogRef<UploadFotoFuncionarioDialog>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataFuncionario) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.funcionarioService.uploadFuncionarioFoto(this.croppedImage, this.data.id).subscribe(result=>{
    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }
}