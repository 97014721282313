import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { Turma, Disciplina } from "../model/turma.model";

@Injectable()

export class TurmaService{

    constructor(private http: HttpClient){}

    create(turma: Turma): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/turmas`, turma)
        .pipe(map((result: any) => result))
    }

    createDisciplina(disciplina: Disciplina): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/disciplinas`, disciplina)
        .pipe(map((result: any) => result))
    }

    obterProfessorDisciplina(idDisciplina: number): Observable<Disciplina[]> {
        return this.http.get<Disciplina[]>(`${BACKEND_API}/turmas/professor/disciplina/${idDisciplina}`)
    }  

    obterDisciplinaTurma(idTurma: number, ano: number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/disciplinas/turma/${idTurma}/${ano}`)
    }  

    obterSerie(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/series`)
    }  

    obterTurma(ano:number): Observable<Turma[]> {
        return this.http.get<Turma[]>(`${BACKEND_API}/turmas/lista/${ano}`)
    }   

    obterTurmaCombo(ano:number): Observable<Turma[]> {
        return this.http.get<Turma[]>(`${BACKEND_API}/turmas/combo/${ano}`)
    }   

    obterTurmaComboMensalidade(ano:number): Observable<Turma[]> {
        return this.http.get<Turma[]>(`${BACKEND_API}/turmas/combomensalidade/${ano}`)
    }  

    obterTurmaDisciplinaProfessor(idTurma:number, ano:number): Observable<Turma[]> {
        return this.http.get<Turma[]>(`${BACKEND_API}/turmas/disciplinaprofessor/${idTurma}/${ano}`)
    }  

    obterTurmaId(idTurma:number, ano:number): Observable<Turma> {
        return this.http.get<Turma>(`${BACKEND_API}/turmas/${idTurma}/${ano}`)
    } 

    obterTurmaIdSerie(idSerie:number): Observable<Turma[]> {
        return this.http.get<Turma[]>(`${BACKEND_API}/series/${idSerie}`)
    }  

    update(turma: Turma): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/turmas`, turma)
        .pipe(map((result: any) => result))
    }
    
    updateDisciplina(disciplina: Disciplina): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/disciplinas`, disciplina)
        .pipe(map((result: any) => result))
    }

}