import { Component, OnInit, ViewChild } from '@angular/core';
import { FinanceiroService } from '@shared/service/financeiro.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-transacoes',
  templateUrl: './transacoes.component.html',
  styleUrls: ['./transacoes.component.scss']
})
export class TransacoesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['data', 'valor', 'funcionario']
  dataSource: MatTableDataSource<any>;
  
  constructor(private financeiroService : FinanceiroService) { }

  ngOnInit() {
    this.financeiroService.obterTransferenciaBancaria().subscribe(result=>{
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

}
