import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotaService } from '@shared/service/nota.service';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { Turma } from 'src/app/shared/model/turma.model';
import { AlunoService } from 'src/app/shared/service/aluno.service';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { LoginService } from 'src/app/shared/service/login.service';
import { Aluno } from 'src/app/shared/model/aluno.model';

@Component({
  selector: 'app-boletim',
  templateUrl: './boletim.component.html',
  styleUrls: ['./boletim.component.scss']
})
export class BoletimComponent implements OnInit {

  avaliacao:string[]= ['nome','id']

  columns: any[] = [{ columnDef: 'nome', header: 'Disciplina', cell: 'nomeDisciplina', size: '30', edicao: '0'}]
  displayedColumns: any
  dataSource: any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  idTurma: number
  idAluno: number

  alunos: Aluno[]
  turmas: Turma[]

  avaliacaoGrid: string[] = [] 
  
  constructor(private turmaService: TurmaService,
              private loginService : LoginService,
              private alunoService: AlunoService,
              private notaService: NotaService) { 
                this.notaService.obterAvaliacoesBoletim(this.loginService.retornarAno()).subscribe(result=>{

                  let tamanho = (70/ result.length)
                  let x = 0 

                  result.forEach(element => {
                    let liberacao = 'N'
                    if(element.dataLiberacao!=null){
                      if(moment().format()>= moment(element.dataLiberacao).format()){
                        liberacao = 'S'
                      }
                    }

                    this.columns.push({ columnDef: element.id.toString()=="nome" ? element.id.toString() : x.toString(), header: element.nomeReduzido== undefined? element.nome : element.nomeReduzido, cell: element.id.toString()=="nome" ? element.id.toString() : x.toString(), size: tamanho, edicao: liberacao=='N' ?'0':'1' })
                    
                    if(element.id.toString()!="nome"){
                      x++
                    }
                  });
                  this.displayedColumns = this.columns.map(c => c.columnDef);
                })
              }

  ngOnInit() {

    this.turmaService.obterTurmaComboMensalidade(this.loginService.retornarAno()).subscribe(result => {
      this.turmas = result
    })

  }

  retornaAlunos(){

    this.alunoService.obterAlunoComboTurma(this.idTurma, this.loginService.retornarAno()).subscribe(result =>{
      this.alunos = result
    })

  }

  retornaNotas(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.notaService.obterNotasAluno(this.idAluno, this.loginService.retornarAno())
      .subscribe(retorno => {
        this.dataSource = new MatTableDataSource(retorno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        swal.close()
      })
  }
}