import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlunoService } from '@shared/service/aluno.service';
import { CepService } from 'src/app/shared/cep/cep.service';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { Turma } from 'src/app/shared/model/turma.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/shared/service/login.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BACKEND_FILE, crypt } from 'src/app/app.api';
import { SimpleCrypt } from 'ngx-simple-crypt';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { UtilService } from 'src/app/shared/util/util.service';
import { Aluno } from 'src/app/shared/model/aluno.model';

export interface DialogData {
  matricula: string;
  ano: number;
}

export interface interfaceId {
  id: number
}

@Component({
  selector: 'app-formaluno',
  templateUrl: './formaluno.component.html',
  styleUrls: ['./formaluno.component.scss']
})

export class FormalunoComponent implements OnInit {

  @ViewChild('cpfContratante') inputCPF:ElementRef;
  
  @ViewChild('nome') inputNome:ElementRef;
  alunoForm: FormGroup
  alunos: Aluno[]

  turma: Turma[]

  idAluno : number
  idAlunoTurma : number
  matriculaAluno : string = undefined
  foto: string
  idEscola: number
  ano: number  
  
  logradouro : string = null
  bairro : string = null
  cidade : string = null
  uf : string = null
  
  logradouroContratante : string = null
  bairroContratante : string = null
  cidadeContratante : string = null
  ufContratante : string = null
  
  liberaGravacao : boolean = true 

  simpleCrypt = new SimpleCrypt();
  temRematricula : boolean = false

  constructor(private alunoService: AlunoService,
              private turmaService: TurmaService,
              private loginService: LoginService,
              private cepService: CepService,
              private utilService: UtilService,
              private fb: FormBuilder,
              private router: Router,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute) { 
                this.matriculaAluno = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']
                this.ano = this.loginService.retornarAno()
                let storage = localStorage.getItem("sapiens")?JSON.parse(localStorage.getItem("sapiens")):''
                this.idEscola = Number(this.simpleCrypt.decode(crypt,storage._idE))
              }

  ngOnInit() {
    this.alunoForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      matricula: this.matriculaAluno == undefined ? this.fb.control('') : this.fb.control({value: '', disabled: true}),
      nascimento: this.fb.control('', [Validators.required]),
      sexo: this.fb.control('',[Validators.required]),
      naturalidade: this.fb.control('',[Validators.required]),
      nacionalidade: this.fb.control(''),
      filiacao1: this.fb.control(''),
      telefoneFiliacao1: this.fb.control(''),     
      filiacao2: this.fb.control(''),
      telefoneFiliacao2: this.fb.control(''),   
      cep: this.fb.control(''),
      complemento: this.fb.control(''),
      cor: this.fb.control('',[Validators.required]),
      tipoSanguineo: this.fb.control('',[Validators.required]),
      contratante: this.fb.control('',[Validators.required]),
      telefoneContratante: this.fb.control('',[Validators.required]),
      cpfContratante: this.fb.control('',[Validators.required]),
      emailContratante: this.fb.control('',[Validators.required, Validators.email]),
      identidadeContratante: this.fb.control('',[Validators.required]),
      cepContratante: this.fb.control(''),
      complementoContratante: this.fb.control(''),
      medicamento: this.fb.control(''),
      deficiencia: this.fb.control(''),
      problemaSaude: this.fb.control(''),
      alergia: this.fb.control(''),
      medicamentoFebre: this.fb.control(''),
      idTurma: this.fb.control('',[Validators.required]),
      mensalidade: this.fb.control('',[Validators.required]),
      pessoaBusca1: this.fb.control(''),
      pessoaBuscaIdentidade1: this.fb.control(''),
      pessoaBuscaTelefone1: this.fb.control(''),
      pessoaBusca2: this.fb.control(''),
      pessoaBuscaIdentidade2: this.fb.control(''),
      pessoaBuscaTelefone2: this.fb.control(''),
      pessoaBusca3: this.fb.control(''),
      pessoaBuscaIdentidade3: this.fb.control(''),
      pessoaBuscaTelefone3: this.fb.control(''),
      pessoaBusca4: this.fb.control(''),
      pessoaBuscaIdentidade4: this.fb.control(''),
      pessoaBuscaTelefone4: this.fb.control('')
    })

    this.getAluno(this.matriculaAluno)

    this.turmaService.obterTurmaCombo(this.ano).subscribe(result=>{
      this.turma = result
    })

    this.retornarFoto()

  }
  
  buscaCEP(cepBusca:string){
    this.cepService.buscaCEP(cepBusca).subscribe(result => {
      if(result.cep!= undefined){
        this.logradouro = result.logradouro
        this.bairro = result.bairro
        this.cidade = result.localidade
        this.uf = result.uf
      } else {
        swal.fire({
          title: 'Sapiens',
          text: 'Cep incorreto!',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
        this.alunoForm.patchValue({
          cep: ""
        })
      }
    })
  }

  buscaCEPContratante(cepBusca:string){
    this.cepService.buscaCEP(cepBusca).subscribe(result => {
      if(result.cep!= undefined){
        this.logradouroContratante = result.logradouro
        this.bairroContratante = result.bairro
        this.cidadeContratante = result.localidade
        this.ufContratante = result.uf
      } else {
        swal.fire({
          title: 'Sapiens',
          text: 'Cep incorreto!',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
        this.alunoForm.patchValue({
          cepContratante: ""
        })
      }
    })
  }
  
  carregaAluno(matricula: any){

    if(matricula.length==8){
      this.alunoService.obterAlunoMatricula(matricula).subscribe(result => {

        this.verificaRematricula()
        this.idAluno =  result[0].id
        this.matriculaAluno = result[0].matricula
        this.logradouro = result[0].logradouro
        this.bairro = result[0].bairro
        this.cidade = result[0].cidade
        this.uf = result[0].uf

        this.logradouroContratante = result[0].logradouroContratante
        this.bairroContratante = result[0].bairroContratante
        this.cidadeContratante = result[0].cidadeContratante
        this.ufContratante = result[0].ufContratante

        this.idAlunoTurma = result[0].idAlunoTurma

        this.alunoForm.patchValue({
          id: result[0].id,
          matricula: result[0].matricula,
          nome: result[0].nome,
          nascimento: moment(result[0].nascimento).add(0,'days').format('YYYY-MM-DD'),
          sexo: result[0].sexo,          
          naturalidade: result[0].naturalidade,
          nacionalidade: result[0].nacionalidade,
          cep: result[0].cep,
          logradouro : this.logradouro,
          complemento: result[0].complemento,
          bairro : this.bairro,
          cidade : this.cidade,
          uf : this.uf,
          filiacao1: result[0].filiacao1,
          telefoneFiliacao1: result[0].telefoneFiliacao1,
          filiacao2: result[0].filiacao2,
          telefoneFiliacao2: result[0].telefoneFiliacao2,
          cor: result[0].cor,
          tipoSanguineo: result[0].tipoSanguineo,
          contratante: result[0].contratante,
          telefoneContratante: result[0].telefoneContratante,
          cpfContratante: result[0].cpfContratante,
          emailContratante: result[0].emailContratante,
          identidadeContratante: result[0].identidadeContratante,
          cepContratante: result[0].cepContratante,
          logradouroContratante : this.logradouroContratante,
          complementoContratante: result[0].complementoContratante,
          bairroContratante : this.bairroContratante,
          cidadeContratante : this.cidadeContratante,
          ufContratante : this.ufContratante,
          medicamento: result[0].medicamento,
          deficiencia: result[0].deficiencia,
          problemaSaude: result[0].problemaSaude,
          alergia: result[0].alergia,
          medicamentoFebre: result[0].medicamentoFebre,
          idTurma: result[0].idTurma,
          mensalidade: result[0].mensalidade,

          pessoaBusca1 : result[0].pessoaBusca1,
          pessoaBuscaIdentidade1 : result[0].pessoaBuscaIdentidade1,
          pessoaBuscaTelefone1 : result[0].pessoaBuscaTelefone1,
          pessoaBusca2 : result[0].pessoaBusca2,
          pessoaBuscaIdentidade2 : result[0].pessoaBuscaIdentidade2,
          pessoaBuscaTelefone2 : result[0].pessoaBuscaTelefone2,
          pessoaBusca3 : result[0].pessoaBusca3,
          pessoaBuscaIdentidade3 : result[0].pessoaBuscaIdentidade3,
          pessoaBuscaTelefone3 : result[0].pessoaBuscaTelefone3,
          pessoaBusca4 : result[0].pessoaBusca4,
          pessoaBuscaIdentidade4 : result[0].pessoaBuscaIdentidade4,
          pessoaBuscaTelefone4 : result[0].pessoaBuscaTelefone4
        }) 
      })
    }
  }
  
  async getAluno(matriculaAluno: string){

    if(matriculaAluno) {

      this.alunoService.obterAlunoMatriculaAno(matriculaAluno, this.ano).subscribe(result => {

        this.idAluno = result[0].id

        this.logradouro = result[0].logradouro
        this.bairro = result[0].bairro
        this.cidade = result[0].cidade
        this.uf = result[0].uf

        this.logradouroContratante = result[0].logradouroContratante
        this.bairroContratante = result[0].bairroContratante
        this.cidadeContratante = result[0].cidadeContratante
        this.ufContratante = result[0].ufContratante

        this.idAlunoTurma = result[0].idAlunoTurma

        this.alunoForm.patchValue({
          id: result[0].id,
          matricula: result[0].matricula,
          nome: result[0].nome,
          nascimento: moment(result[0].nascimento).add(0,'days').format('YYYY-MM-DD'),
          sexo: result[0].sexo,          
          naturalidade: result[0].naturalidade,
          nacionalidade: result[0].nacionalidade,
          cep: result[0].cep,
          logradouro : this.logradouro,
          complemento: result[0].complemento,
          bairro : this.bairro,
          cidade : this.cidade,
          uf : this.uf,
          filiacao1: result[0].filiacao1,
          telefoneFiliacao1: result[0].telefoneFiliacao1,
          filiacao2: result[0].filiacao2,
          telefoneFiliacao2: result[0].telefoneFiliacao2,
          cor: result[0].cor,
          tipoSanguineo: result[0].tipoSanguineo,
          contratante: result[0].contratante,
          telefoneContratante: result[0].telefoneContratante,
          cpfContratante: result[0].cpfContratante,
          emailContratante: result[0].emailContratante,
          identidadeContratante: result[0].identidadeContratante,
          cepContratante: result[0].cepContratante,
          logradouroContratante : this.logradouroContratante,
          complementoContratante: result[0].complementoContratante,
          bairroContratante : this.bairroContratante,
          cidadeContratante : this.cidadeContratante,
          ufContratante : this.ufContratante,
          medicamento: result[0].medicamento,
          deficiencia: result[0].deficiencia,
          problemaSaude: result[0].problemaSaude,
          alergia: result[0].alergia,
          medicamentoFebre: result[0].medicamentoFebre,
          idTurma: result[0].idTurma,
          mensalidade: result[0].mensalidade,

          pessoaBusca1 : result[0].pessoaBusca1,
          pessoaBuscaIdentidade1 : result[0].pessoaBuscaIdentidade1,
          pessoaBuscaTelefone1 : result[0].pessoaBuscaTelefone1,
          pessoaBusca2 : result[0].pessoaBusca2,
          pessoaBuscaIdentidade2 : result[0].pessoaBuscaIdentidade2,
          pessoaBuscaTelefone2 : result[0].pessoaBuscaTelefone2,
          pessoaBusca3 : result[0].pessoaBusca3,
          pessoaBuscaIdentidade3 : result[0].pessoaBuscaIdentidade3,
          pessoaBuscaTelefone3 : result[0].pessoaBuscaTelefone3,
          pessoaBusca4 : result[0].pessoaBusca4,
          pessoaBuscaIdentidade4 : result[0].pessoaBuscaIdentidade4,
          pessoaBuscaTelefone4 : result[0].pessoaBuscaTelefone4
        })

      })

    }
  }

  gravar(aluno : Aluno){

    this.liberaGravacao = false

    if(this.matriculaAluno == undefined){
      this.save(aluno)
    }else{
      this.update(aluno)
    }

  }

  limpar(formDirective: FormGroupDirective){
    
    this.matriculaAluno=undefined
    
    formDirective.resetForm()
    this.alunoForm.reset()
    
    this.alunoForm.patchValue({
      status : true
    })
    
    this.inputNome.nativeElement.focus()

  }

  listaCEP(cep: string){
    if(cep!=null){
      if(cep.length==8){
        this.buscaCEP(cep)
      }else{
        this.logradouro = null
        this.bairro = null
        this.cidade = null
        this.uf = null
      }
    }
  }

  listaCEPContratante(cep: string){
    if(cep!=null){
      if(cep.length==8){
        this.buscaCEPContratante(cep)
      }else{
        this.logradouroContratante = null
        this.bairroContratante = null
        this.cidadeContratante = null
        this.ufContratante = null
      }
    }
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(UploadFotoDialog, {
      width: '400px',
      height: '600px',
      data:{ matricula : this.matriculaAluno, ano : this.ano}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.retornarFoto()
      }, 3000 );
    });
  }

  openDialogRematricula(): void {
    const dialogRef = this.dialog.open(Rematricula, {
      width: '400px',
      height: '226px',
      disableClose: true,
      data:{id : this.idAluno}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.verificaRematricula()
    });
  }

  rematricula(){
  swal.fire({
    title: 'Sapiens',
    text: 'Deseja realmente realizar a rematrícula?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: "Sim",
    cancelButtonText: "Cancelar"
  })
  .then(willDelete => {
    if (willDelete.value) {

      let tmp = undefined
      let data = {}

      this.turma.forEach(element => {

        data[element.id] = element.nome

      });

      let turmaCombo : any[] = []
      turmaCombo.push(tmp)

      this.openDialogRematricula()

    }

  })

  }

  retornarFoto(){
    this.alunoService.obterAlunoFoto(this.matriculaAluno, this.ano).subscribe(result=>{
      this.foto = result==null?null:result[0]
      this.foto = result==null?null: BACKEND_FILE + "/" + this.idEscola + "/foto/alunos/" + this.ano +"/"+ result
    })
  }

  retornaValorTurma(idTurma: any){
    this.turmaService.obterTurmaId(idTurma, this.ano).subscribe( result =>{
      this.alunoForm.patchValue({
        mensalidade:result[0].valor
      })
    })
  }

  save(aluno : Aluno){

    aluno.logradouro = this.logradouro
    aluno.bairro = this.bairro
    aluno.cidade = this.cidade
    aluno.uf = this.uf
    aluno.logradouroContratante = this.logradouroContratante
    aluno.bairroContratante = this.bairroContratante
    aluno.cidadeContratante = this.cidadeContratante
    aluno.ufContratante = this.ufContratante
    aluno.ano = this.ano
    
    this.alunoService.create(aluno).subscribe(result=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Aluno cadastrado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      this.matriculaAluno = result.matricula

    },(error)=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Ocorreu um erro no cadastro de alunos.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
      this.liberaGravacao = true
    })
  }

  setaCEPContratante(alunoForm: any){
    if(this.logradouro==null){
      swal.fire({
        title: 'Sapiens',
        text: 'Informe um CEP válido!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    } else if(alunoForm.complemento =="") {
      swal.fire({
        title: 'Sapiens',
        text: 'Informe o complemento!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      swal.fire({
        title: 'Sapiens',
        text: 'Deseja realmente colocar esse CEP para o contratate?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      })
      .then(retorno => {
        if (retorno.value) {
          this.alunoForm.patchValue({
            cepContratante: alunoForm.cep,
            complementoContratante: alunoForm.complemento
          })
          this.listaCEPContratante(alunoForm.cep)
        }
      })
    }
  }

  setaContratante(alunoForm: any, campo:number){

    let erroContratante = false
    
    if(campo==1){
      if(alunoForm.filiacao1=='' || alunoForm.telefoneFiliacao1==''){
        erroContratante=true
      }
    }else{
      if(alunoForm.filiacao2=='' || alunoForm.telefoneFiliacao2==''){
        erroContratante=true
      }
    }

    if(erroContratante){
      swal.fire({
        title: 'Sapiens',
        text: 'Todos os campos do Contratante devem ser preenchidos!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    } else {

      swal.fire({
        title: 'Sapiens',
        text: 'Deseja realmente colocar esses dados como contratate?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      })
      .then(retorno => {
        if (retorno.value) {
          if(campo==1){
            this.alunoForm.patchValue({
              contratante: alunoForm.filiacao1,
              telefoneContratante: alunoForm.telefoneFiliacao1
            })
          }else{
            this.alunoForm.patchValue({
              contratante: alunoForm.filiacao2,
              telefoneContratante: alunoForm.telefoneFiliacao2
            })
          }
        }
      })
    }
  }

  update(aluno: Aluno) {

    aluno.logradouro = this.logradouro
    aluno.bairro = this.bairro
    aluno.cidade = this.cidade
    aluno.uf = this.uf
    aluno.logradouroContratante = this.logradouroContratante
    aluno.bairroContratante = this.bairroContratante
    aluno.cidadeContratante = this.cidadeContratante
    aluno.ufContratante = this.ufContratante

    aluno.idAlunoTurma = this.idAlunoTurma
    aluno.ano = this.ano

    this.alunoService.update(aluno).subscribe(result=>{
      
      this.liberaGravacao = true

      swal.fire({
        title: 'Sapiens',
        text: 'Aluno alterado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/alunos'])
        }, 70 );
      },(error: HttpErrorResponse)=>{
    })
  }

  validaCPF(cpf: string){
    if(cpf!=''){
      if(!this.utilService.validarCPF(cpf)){
        swal.fire({
          title: 'Sapiens',
          text: 'CPF inválido!',
          icon: 'info',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout( () => {
          this.alunoForm.patchValue({
            cpf: ""
          })
          this.inputCPF.nativeElement.focus()
        }, 2001 );
      }
    }
  }

  verificaRematricula(){
    this.loginService.obterAnos().subscribe(resultAno=>{
      this.alunoService.obterAlunoMatriculaAno(this.matriculaAluno, resultAno[0].ano).subscribe(rematricula => {
        if(rematricula.length==0){
          this.temRematricula = true
        } else {
          this.temRematricula = false
        }
      })
    })
  }

}

@Component({
  selector: 'uploadfoto',
  templateUrl: './uploadfoto.html',
})

export class UploadFotoDialog {
  imageChangedEvent: any = '';
  croppedImage: any = ''

  constructor(private alunoService: AlunoService,
              public dialogRef: MatDialogRef<UploadFotoDialog>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.alunoService.uploadAlunoFoto(this.croppedImage, this.data.matricula, this.data.ano.toString()).subscribe(result=>{

    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }
}

@Component({
  selector: 'rematricula',
  templateUrl: './rematricula.html', 
})

export class Rematricula {

  turma: Turma[]
  idAluno : number
  idTurma : number
  mensalidade : number
  ano : number

  constructor(private turmaService: TurmaService,
              private alunoService: AlunoService,
              public dialogRef: MatDialogRef<Rematricula>,
              private loginService: LoginService,
              @Inject(MAT_DIALOG_DATA) public data: interfaceId) {
                this.idAluno = data.id
                this.loginService.obterAnos().subscribe(resultAno=>{
                  this.ano = resultAno[0].ano
                  this.turmaService.obterTurmaCombo(this.ano).subscribe(result=>{
                    this.turma = result
                  })
                })

              }

  gravar(){

    this.alunoService.rematricula({"idAluno": this.idAluno, "idTurma":this.idTurma, "mensalidade":this.mensalidade, "ano": this.ano}).subscribe(result=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Aluno rematriculado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      setTimeout( () => {
        this.onNoClick()
      }, 1000 );


    },(error)=>{
      swal.fire({
        title: 'Sapiens',
        text: 'Ocorreu um erro no cadastro de alunos.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000
      })

    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  retornaValorTurma(){
    this.turmaService.obterTurmaId(this.idTurma, this.ano).subscribe( result =>{
      this.mensalidade = result[0].valor
    })
  }

}