import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-conselhoclasse',
  templateUrl: './conselhoclasse.component.html',
  styleUrls: ['./conselhoclasse.component.scss']
})
export class ConselhoclasseComponent implements OnInit {

  documento: string 

  ckeConfig: any;
  log: string = '';
  @ViewChild("ckeDocumento") ckeditor: any;
  
  constructor() { }

  ngOnInit() {
  }

}
