import { Component, OnInit } from '@angular/core';
import { FinanceiroService } from '@shared/service/financeiro.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-extratobancario',
  templateUrl: './extratobancario.component.html',
  styleUrls: ['./extratobancario.component.scss']
})
export class ExtratobancarioComponent implements OnInit {
  
  valor: number
  aguardando: number
  extrato: any
  valorTransferencia: number
  valorLiberado: number

  constructor(private financeiroService : FinanceiroService) { }

  ngOnInit() {

    this.load()

  }

  load(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.financeiroService.obterExtrato().subscribe(result=>{
      this.extrato = result

      this.valor = this.extrato.available.amount/100
      this.aguardando = this.extrato.waiting_funds.amount/100

      if(this.valor < 8.67){
        this.valorLiberado = 0
      } else {
        this.valorLiberado = this.valor - 3.67
      }
      swal.close()
      
    })
  }

  // só poderá realizar tranferência de valor igual ou maior que R$ 5,00
  tranferencia(){
    if(Number(this.valorTransferencia) > Number(this.valorLiberado) ){
      swal.fire({
        title: 'Sapiens',
        text: 'Valor acima do permitido.\nVocê só pode tranferir até R$' + this.valorLiberado.toFixed(2) + "!",
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000
      })
    } else if(this.valorTransferencia < 5){
      swal.fire({
        title: 'Sapiens',
        text: 'Valor de tranferência deve ser maior ou igual a R$ 5.00!',
        icon: 'warning',
        showConfirmButton: false,
        timer: 3000
      })
    } else {

      swal.fire({
        title: 'Sapiens',
        text: 'Deseja realmente realizar a transferência de R$' + this.valorTransferencia.toFixed(2) + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      })
      .then(retorno => {
        if (retorno.value) {
          swal.fire({
            title: 'Sapiens',
            text: 'Uma vez realizada a tranferência, a mesma não poderá ser cancelada. Deseja realemente fazer a transferência?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar"
          })
          .then(retorno => {
            if (retorno.value) {
              this.financeiroService.transferencia(this.valorTransferencia.toFixed(2)).subscribe(result =>{
                this.valorTransferencia=0
                swal.fire({
                  title: 'Sapiens',
                  text: 'Transferência realizada com sucesso!',
                  icon: 'info',
                  showConfirmButton: false,
                  timer: 2000
                })
                setTimeout( () => {
                  this.load()
                }, 2000 );
              })
            } else {
              swal.fire({
                title: 'Sapiens',
                text: 'Transferência cancelada!',
                icon: 'info',
                showConfirmButton: false,
                timer: 2000
              })
            }
          })
        } else {
          swal.fire({
            title: 'Sapiens',
            text: 'Transferência cancelada!',
            icon: 'info',
            showConfirmButton: false,
            timer: 2000
          })
        }
      })

    }
  }
}
