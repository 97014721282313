import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailService } from '@shared/service/email.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { EmailMensalidade } from 'src/app/shared/model/email.model';

@Component({
  selector: 'app-emailinadimplentes',
  templateUrl: './emailinadimplentes.component.html',
  styleUrls: ['./emailinadimplentes.component.scss']
})
export class EmailinadimplentesComponent implements OnInit {
  emailForm: FormGroup
  email: EmailMensalidade
  id: number
  enviarEmail:boolean = true

  constructor(private emailService: EmailService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.emailForm = this.fb.group({
      titulo: this.fb.control(''),
      mensagem: this.fb.control('')
    })

    this.emailService.obterEmailMensalidade(2).subscribe(retorno =>{
      this.emailForm.patchValue({
        titulo: retorno.titulo,
        mensagem : retorno.mensagem
      })
      this.id = retorno.id
    })

  }

  gravar(email:EmailMensalidade){

    email.id = this.id

    this.emailService.updateEmailMensalidade(email).subscribe(result=>{

      swal.fire({
        title: 'Sapiens',
        text: 'E-mail de Inadimplentes alterado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      },(error: HttpErrorResponse)=>{
    })
  }

  testar(){

    this.enviarEmail = false
    moment.locale('pt-br')
    this.emailService.enviarEmailMensalidade(2, moment().format("MMMM"), Number(moment().format("YYYY")),1).subscribe(retorno=> {
      swal.fire({
        title: 'Sapiens',
        text: 'E-mail enviado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })
      this.enviarEmail = true
    })
  }

}