import { Component, OnInit } from '@angular/core';
import { FuncionarioService } from 'src/app/shared/service/funcionario.service';
import { AdministracaoService } from '@shared/service/administracao.service';
import { TipoFuncionario } from 'src/app/shared/model/funcionario.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-acesso',
  templateUrl: './acesso.component.html',
  styleUrls: ['./acesso.component.scss']
})
export class AcessoComponent implements OnInit {

  tipoFuncionario :  TipoFuncionario[]
  acessos: any[] = []

  idTipoFuncionario: number

  displayedColumns = ['menu', 'submenu', 'visivel', 'alterar', 'incluir', 'excluir']
  dataSource: MatTableDataSource<any>;
  
  constructor(private funcionarioService: FuncionarioService,
              private administracaoService: AdministracaoService ) { }

  ngOnInit() {
    this.funcionarioService.obterTipoFuncionarioListaUtilizada().subscribe(retorno =>{
      this.tipoFuncionario = retorno
    })
  }

  retornaAcessos(){  
    this.administracaoService.obterAcessos(this.idTipoFuncionario).subscribe(result=>{
      this.acessos=[]
      result.forEach(element => {
        if(element.pai==0){
          this.acessos.push({"id": element.id, "idPagina": element.idPagina, "menu": element.nome , "submenu":"", "visivel": element.visivel,
                             "alterar" : element.alterar, "incluir": "D", "excluir": "D", "editavel" : "S", "principal":"S"})

          result.forEach(elementSub => {
            if(elementSub.pai== element.idPagina){
              this.acessos.push({"id": elementSub.id, "idPagina": elementSub.idPagina, "menu": "" , "submenu": elementSub.nome, 
                                 "visivel": elementSub.visivel, "alterar" : elementSub.alterar, "incluir": elementSub.incluir, 
                                 "excluir": elementSub.excluir, "editavel" : element.visivel=="N"?"N":"S", "principal":"N"})
            }
          })

        }  
      })
      this.dataSource = new MatTableDataSource(this.acessos);
    })
  }

  create(id:string, tipo:string){
    this.administracaoService.create({"idPagina": id, "idTipoFuncionario": this.idTipoFuncionario}).subscribe(result=>{
      this.administracaoService.update({"id": result.id, "status": "S", "tipo":tipo}).subscribe(result=>{
        this.retornaAcessos()
      })      
    })
  }

  update(id:string, valor: any, tipo:string, principal:string){

    this.administracaoService.update({"id": id, "status": valor.checked==true?"S":"N", "tipo":tipo}).subscribe(result=>{
      if(tipo=='V' && valor.checked==false){
        this.update(id, false,"A","N")
        this.update(id, false,"E","N")
        this.update(id, false,"I","N")
      }
  
      if(tipo=='V'){
        setTimeout( () => {
          this.retornaAcessos()
        }, 500 )
      }
    })
  }
}