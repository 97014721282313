import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { clearTable, emitAction } from '../redux/components.actions';
import { loadTable } from '../redux/components.selectors';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @ViewChild(MatPaginator) matPaginator: MatPaginator
  @ViewChild(MatSort) matSort: MatSort

  dataColumns: any[] = []
  displayedColumns: string[] = []
  dataSource: MatTableDataSource<any>

  nameId: string
  blockRefresh: boolean = false

  constructor(private store: Store<AppState>) {
      this.store.dispatch(new clearTable())
  }

  ngOnInit(): void {
    this.store.select(loadTable).subscribe(result=>{

      if(result.dataColumns && !this.blockRefresh){

        this.blockRefresh = true
        this.dataColumns = result.dataColumns

        if(result.dataTable[0].id){
          this.nameId = 'id'
        } else {
          this.nameId = '_id'
        }
        
        this.dataColumns.forEach(element => {
          this.displayedColumns[this.displayedColumns.length] = element.name
        });

        this.dataSource = new MatTableDataSource(result.dataTable);
        this.dataSource.paginator = this.matPaginator;
        this.dataSource.sort = this.matSort;

      } else if(result.dataColumns) {

        if(result.filter != undefined){
            this.filterTable(result.filter)
        }

      }

    })
  }

  delete(id:any){
    this.store.dispatch(new emitAction({action: {action: 'Delete', id: id}}))
  }

  filterTable(filter: any){
    this.dataSource.filter = filter ? filter.trim().toLocaleLowerCase() : ''    
  }

  update(id:any){
    this.store.dispatch(new emitAction({action: {action: 'Update', id: id}}))
  }

}
