import {Component, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

import {LoginService} from '@shared/service/login.service'
import {Usuario} from './usuario.model'
import {ActivatedRoute, Router} from "@angular/router";
import swal from 'sweetalert2';
import { crypt } from 'src/app/app.api';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  preserveWhitespaces: true
})
export class LoginComponent implements OnInit {

  usuario: Usuario
  loginForm: FormGroup
  redirectTo: string
  validacao: boolean = false

  simpleCrypt = new SimpleCrypt();
  
  constructor(private loginService: LoginService,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    localStorage.removeItem("sapiens")
    swal.close()
    this.loginForm = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
      conected: this.fb.control(''),
    })
    this.redirectTo = this.activatedRoute.snapshot.params['to'] || btoa('dashboard/home')
  }

  login(usuario: Usuario) {
    this.loginService.login(usuario).subscribe({
      next: (retorno) =>{
        if(retorno["errors"] != undefined){
          this.validacao = true
        } else {
          this.usuario = retorno
          let storage = {"_nome":this.simpleCrypt.encode(crypt,retorno.nome), 
                        "accessToken":retorno.accessToken, 
                        "id": this.simpleCrypt.encode(crypt,retorno.id.toString()), 
                        "_idE": this.simpleCrypt.encode(crypt,retorno._idE.toString()),
                        "_tipo": this.simpleCrypt.encode(crypt,retorno.tipo.toString())}
          localStorage.setItem('sapiens', JSON.stringify(storage))
          this.router.navigate(['dashboard'])
        }
      },
      error: () =>{
        console.log("error")  
      }
    })
  }
}