import { Component, OnInit } from '@angular/core';
import { LoginService } from '@shared/service/login.service';
import { Router } from '@angular/router';
import { Usuario } from '../security/login/usuario.model';
import { SimpleCrypt } from "ngx-simple-crypt";
import { crypt } from '@app/app.api';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  anos: any[]
  ano: number
  id: number
  nome: string

  simpleCrypt = new SimpleCrypt();
  loggedIn: boolean
  
  constructor( private loginService: LoginService, private router: Router) {
    this.loggedIn = this.isloggedin()
   }
  
  ngOnInit() {

    this.alteraAno()

    console.log("Versão: 05/02/2023")

    this.loginService.obterDadosEscola().subscribe(result=>{
      this.id = result.id
      this.nome = result.descricao
    })

    let dadosMain = JSON.parse(sessionStorage.getItem("main"))

    if(dadosMain){
      this.ano = Number(this.simpleCrypt.decode(crypt, dadosMain.ano))
    } else {
      this.loginService.obterAnos().subscribe(result=>{
          this.ano = result[0].ano
          sessionStorage.setItem("main",JSON.stringify({"ano": this.simpleCrypt.encode(crypt, this.ano.toString())}))
      })  
    }  

  }

  alterarAno(){
    swal.fire({
      title: 'Sapiens',
      text: 'Deseja realmente alterar o ano letivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    }).then(yes => {
      if (yes.value) {
        this.router.navigate(['/dashboard/alteraranoletivo'])
      }
    })
  }

  alteraAno() {
    this.loginService.anoEmitter.subscribe(result=>{
      this.ano = Number(result)
    })
  }

  exemplo(teste : any){
    
  }
  
  isloggedin(){
    return this.loginService.isLoggedIn()
  }

  usuario(): Usuario {
    return this.loginService.usuario()
  }

  logout() {
    this.loginService.logout()
    return this.loginService.usuario()
  } 
}
