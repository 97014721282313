import { Component } from '@angular/core';
import { LoginService } from './shared/service/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Sapiens';
  telaCheia: boolean = false

  loggedIn: boolean
  constructor(private loginService: LoginService){
    this.loggedIn = this.isloggedin()
  }

  isloggedin(){
    return this.loginService.isLoggedIn()
  }
}
