import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TurmaService } from 'src/app/shared/service/turma.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { Turma } from 'src/app/shared/model/turma.model';

@Component({
  selector: 'app-turma',
  templateUrl: './turma.component.html',
  styleUrls: ['./turma.component.scss']
})
export class TurmaComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['status', 'nome', 'sala', 'turno', 'valor', 'quantidade', 'acao']
  dataSource: MatTableDataSource<Turma>;
  ano: number

  constructor(private turmaService : TurmaService,
              private loginService : LoginService,
              private dialog: MatDialog,
              private router: Router) {
                this.ano = this.loginService.retornarAno()
              }

  ngOnInit() {
    this.load()
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }
  
  load(){

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.turmaService.obterTurma(this.ano)
    .subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    })
  }

  detalhes(idTurma:number, nomeTurma:string, turno:string){

    const dialogRef = this.dialog.open(DetalheTurma, {
      width: '800px',
      height: '600px',
      data:{ idTurma : idTurma, nomeTurma : nomeTurma, turno : turno}});

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  update(idTurma: number){
    this.router.navigate(['dashboard/turmas/form',idTurma]);
  }

}

@Component({
  selector: 'detalheturma',
  templateUrl: './detalheturma.html',
})
export class DetalheTurma {

  constructor(private dialogRef: MatDialogRef<DetalheTurma>,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}