import { Injectable, Injector } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { LoginService } from '@shared/service/login.service'
import { Usuario } from './login/usuario.model'

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private injector: Injector){}
    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>>{

        const loginService = this.injector.get(LoginService)

        if(localStorage.getItem("sapiens")!=null){
            let usuario: Usuario = JSON.parse(localStorage.getItem("sapiens"))
            let dataAtual = new Date()

            if((usuario.last) < Number(`${dataAtual.getFullYear()}${dataAtual.getMonth()+1}${dataAtual.getDate()}`)){          
                loginService.logout()
            }
        }
        
        if(loginService.isLoggedIn()){         
            const authRequest = request.clone({headers: request.headers.set('Authorization', `${loginService.usuario().accessToken}`)})
            return next.handle(authRequest).pipe(
                catchError((res) => {
                    if (res.status === 401 || res.status === 403) {
                        loginService.logout()
                    //} else if (res.status === 0) {
                    //     loginService.logout()
                    } else {
                        return next.handle(request)
                    }            
                }
            ))
        } else { 
            return next.handle(request)
        }
    }
}