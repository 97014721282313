import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DetalheTurma } from '../turma.component';
import { LoginService } from 'src/app/shared/service/login.service';
import { TurmaService } from 'src/app/shared/service/turma.service';

@Component({
  selector: 'app-turmadetalhe',
  templateUrl: './turmadetalhe.component.html',
  styleUrls: ['./turmadetalhe.component.scss']
})
export class TurmadetalheComponent implements OnInit {
  @Input() idTurma: number
  @Input() nomeTurma: string
  @Input() turno: string
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['disciplina', 'nomeProfessor', 'nota']
  dataSource: MatTableDataSource<any>;
  
  turmas: any = []

  constructor(private turmaService: TurmaService,
              private loginService: LoginService,
              private detalheTurma: DetalheTurma,
              private router: Router) { }

  ngOnInit() {

    swal.fire({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.turmaService.obterTurmaDisciplinaProfessor(this.idTurma, this.loginService.retornarAno())
    .subscribe(retorno=>{
        this.dataSource = new MatTableDataSource(retorno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        swal.close()
    })
  }

  update(){
    this.detalheTurma.onNoClick()
    this.router.navigate(['dashboard/turmas/formdisciplina/', this.idTurma]);   
  }
}
